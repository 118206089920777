import React, { memo } from "react";
import { ListGroupItem } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { DetailHeader } from "../../../Utilities";
import TransmitterConfiguration from "./TransmitterConfiguration";

const TransmitterConfigurationVersions = ({ configurations }) => {
  return (
    <ListGroupItem>
      <DetailHeader
        title={
          <FormattedMessage id="header_mcu_transmitterConfigurationVersions" />
        }
        info={
          <FormattedMessage id="header_mcu_transmitterConfigurationVersions_info" />
        }
      />
      {configurations &&
        configurations.map(({ ...params }, index) => (
          <TransmitterConfiguration key={index} {...params} />
        ))}
    </ListGroupItem>
  );
};

export default memo(TransmitterConfigurationVersions);
