import React, { Fragment, memo, useState } from "react";
import {
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { useIntl } from "react-intl";
import { StatusIndicator, ResultTypes } from "../../Utilities";
import { Route, Switch, useHistory } from "react-router-dom";
import ResultModal from "./ResultModal";
import DeviceTypes, {
  toSimpleDeviceType,
  fromSimpleDeviceType,
} from "../../Utilities/DeviceTypes";

const userHeaders = ["id", "email", "status"];
const deviceHeaders = ["status", "name", "type", "serial", "owner", "group"];
const siteHeaders = ["id", "name", "owner"];
const pageSize = 8;

const tableHeaders = (resultType) => {
  return resultType === "users"
    ? userHeaders
    : resultType === "sites"
    ? siteHeaders
    : deviceHeaders;
};

const Results = ({
  results,
  searchTerm,
  handleStatusChange,
  resultType,
  ...props
}) => {
  const pageCount = Math.ceil(results.length / pageSize);
  const [currentPage, setPage] = useState(0);
  const { formatMessage } = useIntl();
  const handleClick = (e, index) => {
    e.preventDefault();
    setPage(index);
  };

  const history = useHistory();

  const navigate = (id, type) => {
    const simpleType = toSimpleDeviceType(type);
    if (simpleType) {
      history.push(`${resultType}/${simpleType}/${id}`);
    } else {
      history.push(`${resultType}/${id}`);
    }
  };

  return (
    <Fragment>
      {results && !results.length ? (
        <div className="text-muted">
          <h5>{formatMessage({ id: "no_hits" })}</h5>{" "}
        </div>
      ) : (
        <div className="search-results">
          <div className="text-left">
            <h3>
              {formatMessage({ id: "results_with_term" })}
              <strong>{searchTerm}</strong>
            </h3>
          </div>
          <Table className="result-table" striped responsive>
            <thead>
              <tr>
                {tableHeaders(resultType).map((item, i) => (
                  <th key={i} id={i}>
                    {formatMessage({ id: `header_${item}` })}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {results
                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                .map((item) => (
                  <tr key={item.id}>
                    <td>
                      {resultType !== "devices" ? (
                        item.id
                      ) : (
                        <StatusIndicator
                          isOnline={item.isOnline}
                          isAlert={item.isAlert}
                        />
                      )}
                    </td>
                    <td className="td-modal">
                      <Button
                        className="result-modal-btn"
                        color="link"
                        onClick={() => navigate(item.id, item.type)}
                      >
                        <p>
                          {resultType === "users"
                            ? item.email
                            : !item.name
                            ? item.serialNumber
                            : item.name}
                        </p>
                      </Button>
                    </td>
                    {resultType === "devices" && <td>{item.type}</td>}
                    {resultType !== "sites" && (
                      <td>
                        {resultType === "users"
                          ? formatMessage({ id: `status_${item.status}` })
                          : item.serialNumber}
                      </td>
                    )}

                    {resultType !== "users" && (
                      <Fragment>
                        <td>{item.owner.email}</td>
                        {resultType === "devices" && (
                          <td>{item.group && item.group.name}</td>
                        )}
                      </Fragment>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
          {pageCount > 1 && (
            <Pagination>
              <PaginationItem disabled={currentPage <= 0}>
                <PaginationLink
                  onClick={(e) => handleClick(e, currentPage - 1)}
                  previous
                  href="#"
                />
              </PaginationItem>

              {[...Array(pageCount)].map((_page, i) => (
                <PaginationItem active={i === currentPage} key={i}>
                  <PaginationLink onClick={(e) => handleClick(e, i)} href="#">
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={currentPage >= pageCount - 1}>
                <PaginationLink
                  onClick={(e) => handleClick(e, currentPage + 1)}
                  next
                  href="#"
                />
              </PaginationItem>
            </Pagination>
          )}
        </div>
      )}

      <Switch>
        <Route
          exact
          path="/users/:id"
          render={(routeProps) => {
            const { id } = routeProps.match.params;
            return (
              <ResultModal
                detailsId={id}
                handleStatusChange={handleStatusChange}
                resultType={resultType}
                {...props}
              />
            );
          }}
        />
        <Route
          exact
          path="/devices/:type/:id"
          render={(routeProps) => {
            const { type, id } = routeProps.match.params;
            const deviceType = fromSimpleDeviceType(type);
            const resultType =
              deviceType === DeviceTypes.TYPE_CCU1
                ? ResultTypes.TYPE_GATEWAYS
                : ResultTypes.TYPE_DEVICES;
            return (
              <ResultModal
                detailsId={id}
                handleStatusChange={handleStatusChange}
                resultType={resultType}
                deviceType={deviceType}
                {...props}
              />
            );
          }}
        />
        <Route
          exact
          path="/sites/:id"
          render={(routeProps) => {
            const { id } = routeProps.match.params;
            return (
              <ResultModal
                detailsId={id}
                handleStatusChange={handleStatusChange}
                resultType={resultType}
                {...props}
              />
            );
          }}
        />
      </Switch>
    </Fragment>
  );
};

export default memo(Results);
