import React from "react";

export default ({ className, fill }) => (
  <svg
    className={className}
    version="1.1"
    id="Layer_2_xA0_Image_1_"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="392px"
    height="390px"
    viewBox="0 0 392 390"
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M201,329c-4.829,0-9.658,0-14.084,0
		c-4.603-2.819-8.272-6.167-10.916-11.1c0-27.746,0-56.055,0-83.998c2.809-4.571,6.152-8.244,11.052-10.902c4.29,0,9.119,0,13.948,0
		c1.127-11.528,0.231-23.039,0.54-34.027c-3.04-2.239-5.621-1.427-8.026-1.448c-14.226-0.126-23.984-9.905-23.995-24.224
		c-0.014-18.444-0.005-36.888-0.003-55.333c0-18.466-0.013-36.931,0.004-55.396c0.013-14.26,9.843-24,24.156-24.088
		c2.268-0.015,4.65,0.623,6.519-0.473c1.098-0.946,1.237-1.881,1.341-2.915c0.661-6.626,2.836-8.57,9.61-8.575
		c20.628-0.014,41.256-0.007,61.885-0.005c20.159,0,40.318-0.002,60.478,0c8.558,0.001,9.886,1.177,11.082,9.74
		c0.068,0.49,0.36,0.924,1.019,1.604c1.911,1.411,4.498,0.594,6.908,0.615c14.006,0.122,23.953,9.928,23.961,23.86
		c0.021,36.486-0.04,72.973,0.062,109.459c0.015,5.13-0.704,9.928-3.287,14.387c-6.289,10.766-16.764,11.891-27.132,11.424
		c-2.361,2.385-1.601,4.677-1.603,6.746c-0.041,54.123-0.031,108.247-0.034,162.371c0,8.757-2.047,10.762-10.965,10.762
		c-40.468,0.001-80.936,0.002-121.403-0.001c-8.445,0-10.468-2.16-10.634-10.619C201.3,347.576,202.188,338.27,201,329z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M201,329c1.674,0.179,0.962,1.49,0.967,2.291
		c0.053,8.659,0.196,17.324-0.033,25.978c-0.12,4.525,1.229,7.819,6.105,9.731c27.855,0,56.323,0,84.791,0
		c13.823,0,27.646,0.001,41.468,0c5.736-0.001,6.55-0.462,9.778-5.654c0-57.545,0-115.298,0-172.922
		c0.482-0.482,0.835-0.835,1.424-1.424c2.226,0,4.694,0.04,7.161-0.008c7.801-0.149,14.119-3.381,19.134-9.285
		c0.453-0.534-0.16-1.819,1.205-1.707c0.333-0.347,0.667-0.347,1,0c-2.979,5.444-5.856,7.356-11.382,10.167
		c-2.956,1.504-5.713,1.816-8.749,1.831c-2.486,0.011-4.972,0.002-7.369,0.002c-0.441,0.441-0.795,0.795-1.324,1.324
		c0,57.896,0,115.971,0,174.025c-1.689,1.548-3.278,3.002-5.078,4.65c-44.563,0-89.527,0-134.595,0
		c-1.441-1.5-2.934-3.053-4.503-4.686C201,351.982,201,340.491,201,329z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M374,176c-0.333,0-0.667,0-1,0
		c0.103-2.707,2.727-4.713,2.117-7.617c1.413-0.938,0.863-2.386,0.863-3.631c0.023-37.807,0.207-75.614-0.125-113.418
		c-0.074-8.386-3.763-15.576-12.144-19.36c-4.204-1.899-7.694-2.972-11.26-2.974c-2.328-0.001-4.655,0-6.952,0
		c-0.502-0.502-0.973-0.973-1.444-1.443c-0.274-1.773,0.299-3.603-0.351-5.352c-1.186-3.191-4.113-5.196-7.75-5.198
		C314.97,16.993,293.985,17,273,17c-0.328-0.223-0.438-0.444-0.328-0.667C272.78,16.111,272.89,16,273,16
		c22.466,0,44.932,0,67.511,0c1.414,1.515,2.883,3.088,4.494,4.814c0,1.704,0,3.7,0,5.69c0.476,0.476,0.946,0.946,1.495,1.495
		c2.574,0,5.284,0.348,7.864-0.083c3.759-0.627,7.18,0.813,10.042,2.326c3.389,1.79,6.521,4.412,8.72,8.089
		c2.442,4.085,3.947,8.063,3.934,12.896c-0.105,39.768-0.06,79.536-0.06,119.348C375.013,171.831,375.866,174.653,374,176z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M273,16c0,0.333,0,0.667,0,1c-20.286,0-40.573,0.08-60.858-0.061
		c-4.604-0.032-8.225,0.977-10.129,6.127c0,1.068,0,2.728,0,4.464c-0.457,0.413-0.948,0.855-1.629,1.47
		c-2.072,0-4.384-0.023-6.695,0.004c-7.369,0.088-13.654,2.727-18.464,8.347c-2.119,2.477-3.251,5.561-4.281,8.689
		c-1.153,3.501-0.934,7.032-0.937,10.579C169.991,73.746,170,90.873,170,108c-0.333,0.454-0.667,0.454-1,0
		c0-19.289,0.102-38.579-0.066-57.867c-0.046-5.243,2.087-9.488,4.96-13.418c2.817-3.854,7.759-6.938,12.407-8.473
		c0.895-0.296,1.927-0.226,2.896-0.232c3.475-0.024,6.951-0.01,10.267-0.01c2.823-2.173,0.764-5.118,2.023-7.987
		c0.947-0.947,2.358-2.358,4.013-4.013C227.837,16,250.419,16,273,16z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M37,294c-0.011,1.752,0.292,3.366,0.91,5.084
		c1.71,4.759,2.461,9.853,5.004,14.33c-0.688,2.972,1.695,4.971,2.616,7.37c0.849,2.208,1.005,5.021,3.322,6.568
		c-0.25,2.831,2.653,4.169,3.147,6.647c0.563,0.802,0.661,1.555-0.228,2.207c-1.993-0.114-2.77-1.673-3.547-3.121
		c-7.926-14.748-13.449-30.26-15.84-46.934c-1.137-7.925-2.15-15.793-1.785-23.79c0.7-15.365,3.592-30.3,9.097-44.685
		c0.608-1.587,0.956-3.681,3.37-3.779c0.724,1.38,0.615,2.745-0.067,4.102c-1.193,1.824-1.255,4.026-2,6
		c-1.879,4.663-3.008,9.533-4.007,14.444c-2.563,11.21-3.501,22.571-2.782,34.023C34.664,279.691,35.017,286.961,37,294z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M169,108c0.333,0,0.667,0,1,0c0,18.489-0.037,36.979,0.018,55.468
		c0.023,7.592,2.925,14.057,8.868,18.739c2.34,1.843,5.276,3.053,8.353,3.914c3.726,1.045,7.401,0.781,11.113,0.859
		c1.349,0.028,2.679-0.101,3.648,1.715c0,10.745,0.001,21.879-0.002,33.013c0,0.679-0.008,1.37-0.998,1.292
		c0-10.312,0.001-20.624-0.001-30.936c0-1.465,0.312-3.041-1.745-4.064c-1.758,0-3.917-0.164-6.043,0.027
		c-10.272,0.927-17.576-5.69-21.837-12.153c-0.282-3.133-2.526-5.563-2.486-8.997C169.118,147.253,169,127.626,169,108z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M9,302c-1.06-2.615-2.216-5.168-2.033-8.138
		c0.216-3.514-0.685-6.878-1.764-10.206c-0.291-0.896-0.195-1.934-0.196-2.907c-0.012-9.794,0.087-19.589-0.07-29.38
		c-0.042-2.629,1.77-4.899,1.063-7.555c1.579-1.814,0.857-4.011,0.994-6.061c0.105-1.576,0.077-3.035,0.903-4.619
		c1.36-2.606,1.093-5.756,2.03-8.604c0.962-2.92,1.423-5.947,2.643-8.871c0.902-2.161,1.899-4.735,1.448-7.379
		c-0.112-0.657-0.001-1.358,0.981-1.281c0.757-0.837,1.458-2.137,2.547-0.316c0.464,2.437-0.63,4.578-1.359,6.765
		c-6.001,18.013-8.765,36.558-8.665,55.487c0.046,8.813,1.398,17.566,2.712,26.289c0.318,2.113,1.158,4.215,0.23,6.378
		C10.099,302.182,9.609,302.313,9,302z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M49,153c0.085-1.496,1.242-0.85,2-1c1.667-0.751,3.333-0.751,5,0
		c5.2,4.104,9.529,9.115,14.191,13.772c2.574,2.571,2.532,5.815,0.043,8.44c-5.513,5.813-10.032,12.411-14.629,18.891
		c-3.448,4.862-5.78,10.517-8.602,15.822c-0.36,0.678-0.671,1.383-1.004,2.074c-0.438,0.316-0.856,0.279-1.261-0.063
		c-0.85-3.265,1.149-5.739,2.469-8.326c5.083-9.96,11.563-18.993,18.623-27.635c3.609-4.416,3.503-6.132-0.383-10.278
		c-1.363-1.455-2.911-2.744-4.196-4.26c-3.233-3.813-6.925-6.609-12.271-6.197c-0.26-0.279-0.342-0.557-0.25-0.831
		C48.822,153.137,48.912,153,49,153z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M97,287c0.031,2.212-0.392,4.575,2,6
		c0.714,1.31,0.713,2.648,0.165,4.009c-3.201-0.16-3.25-3.037-3.892-4.996c-6.579-20.083-5.897-39.88,2.865-59.228
		c2.055-4.537,4.224-9.04,7.115-13.133c1.14-1.612,2.078-3.45,4.178-4.094c0.583-0.067,1.105,0.081,1.568,0.441
		c0.153,1.054-0.22,1.803-1.021,2.567c-4.388,4.194-6.531,9.713-9.111,15.049c-1.407,2.909-1.619,6.313-3.879,8.999
		c0.795,4.254-2.888,7.946-1.989,12.385c-1.292,7.333-1.292,14.667,0,22C95.826,280.302,95.419,283.85,97,287z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M71,304c-0.63,0.113-0.94-0.269-0.982-0.789
		c-0.066-0.826,0.317-1.897-0.074-2.451c-1.984-2.801-2.058-6.153-3.085-9.216c-0.345-1.03-0.817-1.983-0.871-3.121
		c-0.141-2.996-0.888-5.873-1.756-8.733c-0.274-0.906-0.221-1.929-0.223-2.898c-0.016-7.476,0.058-14.952-0.06-22.426
		c-0.026-1.683,0.734-3.064,0.976-4.583c0.424-2.665,0.951-5.334,1.285-8.02c0.313-2.506,1.078-5.271,2.027-7.572
		c1.107-2.683,0.71-6.12,3.254-8.084c1.154-0.891-0.581-2.889,1.509-3.106c0.905,0.913,1.093,2.033,0.949,3.251
		c-9.453,24.621-10.01,49.462-1.809,74.519C72.191,301.999,71.921,303.115,71,304z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M147,300c-1.12,2.795-4.262,3.115-5.491,4.79
		c-2.088,0.689-2.821-1.045-4.139-0.938c-1.532-3.525-4.887-5.773-6.403-9.337c-1.447-3.401-3.169-6.686-4.84-10.166
		c-0.322-5.383-2.303-10.679-2.326-16.411c-0.023-5.746,2.103-10.998,2.367-16.295c1.682-3.747,3.26-7.256,4.83-10.77
		c0.407-0.912,0.929-1.683,2.002-1.874c1.23,1.265,0.3,2.469-0.09,3.694c-0.169,0.413-0.373,0.809-0.586,1.2
		c-1.134,1.955-1.931,4.048-2.56,6.218c-0.144,0.469-0.324,0.917-0.515,1.366c-3.918,11.224-3.973,22.409,0.269,33.554
		c2.846,5.906,4.999,12.282,10.141,15.993c2.612,0.37,3.813-1.093,5.376-1.57C145.78,299.313,146.459,299.412,147,300z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M62,374c-1.643,2.717-4.301,4.492-6.333,6.884
		c-5.834,0.958-8.743-3.055-11.602-6.962c-0.473-0.646-0.948-1.308-1.514-1.831c-3.087-2.859-5.236-6.447-7.72-9.772
		c-3.463-4.637-6.621-9.482-9.49-14.505c-1.685-2.948-3.215-5.987-4.833-8.972c-0.754-1.39-1.621-3.043-1.508-4.842
		c1.125-0.507,1.828,0.155,2.505,0.896c8.718,15.732,17.182,31.654,30.697,43.26c4.204-0.484,5.396-4.173,8.516-4.684
		C61.205,373.502,61.633,373.679,62,374z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M49,153c0,0.333,0,0.667,0,1c0.626,2.526-1.143,4.101-2.542,5.593
		c-11.403,12.158-19.319,26.504-26.314,41.448c-0.832,1.778-1.31,3.806-3.144,4.959c-0.667,0.333-1.333,0.667-2,1
		c0.123-2.568,1.585-4.751,2.625-6.887c1.41-2.896,1.688-6.433,4.375-8.681c0.104-3.498,2.769-5.864,4.176-8.737
		c2.256-4.605,5.565-8.715,8.591-12.917c2.524-3.506,5.02-7.023,8.012-10.205C44.844,157.379,46.241,154.558,49,153z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M99,297c0-1.333,0-2.667,0-4c1.767,2.094,2.482,4.768,3.51,7.183
		c1.715,4.03,4.253,7.502,6.386,11.243c1.618,2.837,4.231,4.949,5.373,8.193c0.636,1.81,0.817,3.033-0.063,4.825
		c-0.911,1.854-2.017,3.57-3.308,5.041c-2.72,3.101-5.817,5.866-8.681,8.846c-0.693,0.722-1.412,0.675-2.218,0.669
		c-0.349-0.346-0.533-0.763-0.556-1.253c1.665-4.621,6.353-6.607,9.052-10.351c1.242-1.723,3.128-2.999,3.461-5.384
		c0.016-2.278-1.47-3.904-2.698-5.66c-3.323-4.75-6.777-9.423-9.219-14.721C99.372,300.182,98.172,298.805,99,297z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M62,374c-0.333,0-0.667,0-1,0
		c-0.296-3.419,2.742-4.654,4.496-6.664c3.761-4.311,3.963-5.918,0.351-10.297c-4.549-5.515-8.643-11.363-12.803-17.164
		c-0.801-1.116-2.335-2.148-1.044-3.875c0-0.667,0-1.333,0-2c0.834-0.121,1.242,0.586,1.515,1.066
		c3.58,6.307,7.917,12.045,12.526,17.633c1.412,1.712,2.802,3.455,4.267,5.141c2.353,2.709,2.407,5.777-0.144,8.417
		C67.563,368.949,65.176,371.901,62,374z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M132,243c0.001-0.794-0.172-1.612,0.665-2.186
		c0.67-0.458,0.21-1.208,0.335-1.814c2.669-1.418,2.986-4.647,5.189-6.678c3.428-1.495,5.495,1.347,7.616,3.124
		c3.279,2.747,6.138,5.997,9.185,9.044c0,0.979,0,1.968,0,2.973c-2.59,2.738-5.486,5.263-6.293,9.277
		c-0.174,0.862-1.619,1.117-1.697,2.26c-0.419,0.309-0.832,0.317-1.241-0.019c-1.023-3.012,1.178-4.984,2.382-7.26
		c1.002-1.894,2.643-3.447,3.132-6.279c-2.342-4.282-6.223-7.355-10.375-9.588C136.251,236.835,136.567,242.797,132,243z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M37,294c-1.783-1.771-2.109-4.032-1.981-6.365
		c0.25-4.572-1.74-8.965-1.019-13.563c-2.082-4.392-0.509-9.053-1-13.563c2.165-3.626-0.013-7.932,1.893-11.704
		c0.669-1.326,0.123-3.274,0.103-4.938c-0.027-2.198,0.433-4.22,2.004-5.867c0.276,0.223,0.369,0.444,0.276,0.667
		C37.185,238.889,37.093,239,37,239c0.693,2.338-0.953,4.298-1.057,6.541c-0.094,2.022,0.864,4.189-0.867,6.117
		c-0.494,0.55-0.076,1.92-0.076,2.914c0,7.62,0.154,15.246-0.097,22.858c-0.073,2.207,1.16,3.926,1.176,6.049
		c0.015,2.011-0.777,4.093,0.798,6.142C37.598,290.559,37,292.509,37,294z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M146,259c0.333,0,0.667,0,1,0c0.523,2.954-1.22,5.573-1.096,8.565
		c0.231,5.537,2.278,10.284,5.029,14.911c1.597,2.688,4.313,4.649,5.062,7.72c0.087,2.398-1.854,3.344-2.995,4.804
		c-0.692,0.533-1.4,0.637-2.131,0.077c-0.555-1.947,1.423-3.315,1.077-5.987c-3.375-5.166-7.377-10.911-8.636-17.89
		C142.521,266.819,141.737,262.321,146,259z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M111,216c-0.333,0-0.667,0-1,0
		c-1.025-2.333,1.416-3.981,1.383-6.357c-3.495-6.435-8.594-11.333-13.819-14.862c-4.561-0.048-5.043,4.696-8.573,4.391
		c-0.28-0.263-0.371-0.524-0.274-0.783C88.811,198.129,88.906,198,89,198c1.525-2.004,2.458-4.461,4.557-5.98c2.017,0,4,0,6.061,0
		c5.044,5.703,11.381,10.278,15.562,17.117C113.942,211.491,113.776,214.491,111,216z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M100,338c0,0.333,0,0.667,0,1
		c-1.985,1.555-4.308,0.884-6.695,0.996c-4.058-5.656-8.937-10.781-12.446-17.041c-1.719-3.066-3.354-6.285-5.529-9.119
		c-0.686-0.894-0.154-1.899-0.329-2.836c0.425-0.258,0.871-0.315,1.342-0.146c4.637,5.874,7.488,12.893,12.124,18.795
		C91.562,333.59,94.397,337.634,100,338z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M21,335c-0.667-0.333-1.333-0.667-2-1
		c0.293-2.212-1.277-3.896-2.148-5.529c-2.485-4.659-3.477-9.804-5.288-14.652C10.186,310.129,8.828,306.155,9,302
		c0.333,0,0.667,0,1,0c1.668,0.586,1.998,2.068,2.319,3.526c1.784,8.09,4.58,15.832,7.843,23.433
		C20.96,330.818,22.445,332.757,21,335z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M89,198c0,0.333,0,0.667,0,1c0.245,0.441,0.473,1.007,0.226,1.395
		c-3.484,5.458-6.165,11.464-10.9,16.075c-0.512,0.043-0.954-0.114-1.325-0.47c0-0.165-0.059-0.359,0.009-0.49
		c3.185-6.262,6.683-12.318,11.507-17.499C88.593,197.928,88.835,198,89,198z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M95,277c-0.282-0.115-0.817-0.252-0.81-0.343
		c0.291-3.553-1.104-6.908-1.19-10.41c-0.092-3.685,1.53-7.186,1.19-10.904c-0.008-0.089,0.527-0.228,0.81-0.343
		C95,262.333,95,269.667,95,277z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M37,239c0-0.333,0-0.667,0-1c0.034-2.233-0.076-4.419,0.936-6.594
		c0.966-2.077,1.4-4.401,2.063-6.619c0.151-0.508,0.372-0.9,1.002-0.787c0.181,2.431-0.823,4.648-1.479,6.875
		c-0.715,2.425-1.744,4.725-1.545,7.339C38.013,238.714,37.63,239.113,37,239z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M77,216c0.333,0,0.667,0,1,0c1.023,4.16-1.116,7.203-3.575,10.138
		C73.922,226.39,73.446,226.343,73,226c0-1,0-2,0-3c1.643-0.002,0.579-2.145,1.9-2.4C74.293,218.47,76.43,217.593,77,216z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M76,311c-0.333,0-0.667,0-1,0
		c-1.564-0.534-1.667-2.114-1.91-3.275c-0.324-1.549-0.7-2.863-2.09-3.725c0-1,0-2,0-3c0.434-0.365,0.897-0.411,1.396-0.14
		C74.546,303.9,76.751,306.926,76,311z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M97,287c-1.489-1.403-1.965-3.205-1.995-5.165
		C94.98,280.224,95,278.611,95,277c0.276,0.11,0.803,0.244,0.794,0.327C95.47,280.646,97.215,283.703,97,287z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M45,211c0.333,0,0.667,0,1,0c0.369,1.512-0.629,3.024-1.361,3.611
		c-1.256,1.007,0.484,3.135-1.639,3.389c0-1.333,0-2.667,0-4C42.997,212.554,43.552,211.479,45,211z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M151,295c0.667,0,1.333,0,2,0c-1.302,1.789-2.272,3.992-5,4
		c-0.71-1.574,0.554-2.443,1.248-3.52C149.745,295.01,150.266,294.608,151,295z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M150,296c-0.667,1-1.333,2-2,3c-0.692-0.025-1.025,0.308-1,1
		c-0.667,0-1.333,0-2,0C145.217,296.854,146.46,294.991,150,296z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M41,224c0.031-2.212-0.392-4.575,2-6
		C43.074,220.247,42.596,222.31,41,224z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M56,152c-1.667,0-3.333,0-5,0
		C52.631,150.563,54.369,150.563,56,152z"
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M45,211c-1.03,0.758-0.302,2.688-2,3
		c-2.146,0.292-0.353,2.5-1.813,3.551c-1.438,1.036-0.968,3.5-1.558,5.258c-0.538,1.605-1.746,2.889-1.719,4.82
		c0.019,1.322-0.728,2.693-1.922,3.926c0.159,2.842,0.055,5.887-1.645,8.543c-0.527,0.823-0.241,1.582-0.333,2.365
		c-0.248,2.111,0.791,4.531-0.436,6.291c-1.32,1.896,0.53,4.318-1.517,5.945c-0.406,0.323-0.059,1.593-0.059,2.426
		c0,5.83,0.065,11.661-0.029,17.49c-0.044,2.761,1.506,5.247,1.167,8.023c1.404,0.949,0.855,2.4,0.831,3.641
		c-0.06,3.07,0.022,6.107,1.679,8.805c0.407,0.662,0.362,1.25,0.33,1.86c-0.113,2.124-0.107,4.311,1.441,5.883
		c1.327,1.347-0.093,3.119,1.246,4.568c0.753,0.814,1.456,2.264,1.43,3.886c-0.039,2.327,1.46,4.415,2.366,6.471
		c2.171,4.928,3.62,10.249,7.196,14.546c0.883,1.061,0.28,3.233,2.342,3.702c0,0.498-0.194,1.303,0.03,1.445
		c1.999,1.266,2.881,3.361,4.244,5.112c1.367,1.756,2.813,3.506,4.051,5.347c2.143,3.186,4.58,6.122,7.107,8.976
		c3.396,3.836,3.428,6.532-0.087,10.299c-2.118,2.271-4.711,4.103-6.346,6.821c-1.441,0.272-2.045,1.59-2.924,2.494
		c-1.471,1.513-3.095,2.711-5.219,3.567c-2.315-1.383-5.06-2.397-5.934-5.581c-0.152-0.556-0.877-0.967-1.364-1.416
		c-6.032-5.557-10.113-12.567-14.62-19.292c-3.031-4.522-5.094-9.54-7.982-14.104c-0.939-1.483-2.613-2.551-1.958-4.668
		c-0.601-2.862-1.937-5.475-3.07-8.116c-2.48-5.784-4.346-11.724-6.116-17.751C11.12,306.769,11.831,304.03,10,302
		c0.252-2.599-1.641-4.899-1.045-7.545c-1.628-1.258-1.063-3.087-0.879-4.581c0.33-2.678-1.341-4.907-1.178-7.579
		c0.142-2.323,0.697-4.76-0.784-7.13C5.442,274.09,6,272.245,6,270.749c0-3.157,0.229-6.337-0.068-9.467
		c-0.268-2.832,0.85-5.365,1.081-8.067c0.23-2.697-0.806-5.55,0.966-8.077c0.156-0.224,0.053-0.654,0.016-0.982
		c-0.561-4.886,1.669-9.374,2.005-14.13c1.803-2.065,1.17-4.826,1.801-7.202c1.353-5.099,3.211-9.994,4.911-14.952
		c0.228-0.665,0.154-1.258,0.288-1.871c-0.188-2.483,2.474-4.06,2.102-6.602c2.885-2.45,3.034-6.385,5.077-9.405
		c1.834-2.711,3.197-5.97,4.908-8.858c2.95-4.979,6.619-9.525,9.653-14.458c2.889-4.694,7.982-7.536,10.26-12.677
		c0.499,0,1.192,0.215,1.466-0.033c2.646-2.404,4.989-0.67,6.78,0.915c3.852,3.408,7.437,7.135,10.965,10.886
		c2.496,2.653,2.493,5.805,0.009,8.466c-4.913,5.259-8.758,11.312-13.074,17.014c-1.531,2.023-2.712,4.291-3.964,6.391
		c-2.338,3.923-4.497,8.057-6.167,12.371C44.902,210.298,45,210.668,45,211z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M76,311c0-0.162,0.063-0.362-0.009-0.48
		c-1.828-2.966-2.642-6.354-3.991-9.52c-5.763-16.601-8.18-33.414-4.853-50.991C68.715,241.732,70.059,233.536,74,226
		c1.587-3.231,3.029-6.521,4-10c1.705-1.936,2.669-4.332,4.048-6.461c1.529-2.364,2.745-4.979,4.858-6.933
		c1.078-0.997,1.352-2.432,2.094-3.606c1.252-0.231,2.284-1.293,2.505-2.139c0.698-2.671,3.018-2.897,4.574-3.978
		c5.001,1.906,7.54,4.317,16.952,15.934c-0.067,2.769-2.168,4.719-3.031,7.183c-3.182,2.661-5.074,6.3-6.92,9.841
		c-2.057,3.945-4.257,7.848-5.637,12.152c-1.378,4.302-3.421,8.421-3.415,13.06c0.003,1.795-1.474,3.146-1.103,5.097
		c0.286,1.51,0.516,3.282-0.926,4.568c0.536,3.86-0.536,7.714,0.657,11.697c0.782,2.612,1.207,5.594,1.321,8.462
		c0.077,1.922,0.303,3.725,0.932,5.611c1.171,3.512,2.273,7.048,3.608,10.505C98.54,297.049,98.833,297,99,297
		c3.741,8.939,9.993,16.348,14.806,24.16c-0.68,2.625-1.623,4.856-3.714,6.2c-1.996,1.284-2.699,3.627-4.743,5.008
		c-2.075,1.402-4.09,3.259-5.349,5.632c-1.546-0.472-2.494,1.472-4.794,0.77c-0.64-0.459-1.471-2.043-3.174-2.237
		c-0.057-2.133-2.073-2.786-3.175-4.117c-1.01-1.22-1.783-2.605-2.677-3.878C82.291,322.998,78.787,317.197,76,311z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M74,226c-1.17,3.498-2.339,6.996-3.509,10.494
		c-0.6,1.904-0.293,3.971-1.442,5.918c-1.065,1.805-1.471,3.957-1.004,6.03c0.504,2.238-1.114,3.947-1.122,6.08
		c-0.007,2.019,1.006,4.31-0.923,6.069c0.477,3.872-0.964,7.866,0.745,11.662c0.126,0.278,0.281,0.61,0.238,0.889
		c-0.604,3.939,1.563,7.619,1.037,11.562c-0.287,2.151,0.342,4.097,1.974,5.844c0.112,2.682-0.53,5.684,1.947,8.021
		C72.356,298.96,72,300.169,72,301c-0.333,0-0.667,0-1,0c0.324-2.235-2.364-3.507-1.949-5.646c0.436-2.248-1.236-3.925-1.25-6.014
		c-0.002-0.384-0.785-0.783-0.761-1.146c0.31-4.541-1.331-8.768-1.971-13.149c-0.38-2.604-0.069-5.31-0.069-7.97
		c0-2.993,0.02-5.985-0.009-8.978c-0.013-1.456,0.204-2.759,0.933-4.116c0.643-1.198-0.13-2.713,0.655-4.219
		c1.151-2.208,0.118-4.85,0.539-7.324c0.968-1.16,1.812-2.426,1.898-4.13c0.228-4.449,2.074-8.394,3.983-12.31
		C73.333,226,73.667,226,74,226z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M150,296c-2.728,0.008-3.698,2.211-5,4
		c-0.996,2.478-3.255,2.669-5.359,3.079C134.313,298.408,131.164,292.51,129,286c-4.359-12-4.358-24,0-36
		c1.014-2.327,2.077-4.634,3-7c2.139-0.365,2.294-2.547,3.186-3.877c1.274-1.899,2.771-3.511,4.332-5.147
		c0.758,0.229,1.542-0.229,2.347,0.348c4.124,2.959,7.585,6.628,11.169,10.218c0.193,3.343-2.584,5.225-3.944,7.791
		c-1.155,2.177-2.723,4.133-3.089,6.668c-1.961,4.263-2.545,8.415-1.179,13.21c1.212,4.254,3.468,7.857,5.169,11.788
		c0.818,1.894,2.69,2.807,3.938,4.568c0,0.763,0,1.736,0,2.86c-0.635,1.327-2.491,1.878-2.928,3.573
		C150.308,294.975,149.975,295.308,150,296z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={fill}
        d="M129,250c0.531,2.016-1.489,3.617-0.973,5.576
		c0.505,1.917-1.449,3.146-1.112,5.105c0.287,1.665,0.505,3.521-0.852,5.123c-0.411,0.485,0.108,1.658-0.098,2.437
		c-0.505,1.907,1.19,3.241,1.108,5.096c-0.075,1.694-0.83,3.657,0.867,5.104c-0.569,2.648,1.31,4.955,1.059,7.559
		c-1.445-0.413-1.825-1.989-1.891-2.877c-0.411-5.583-2.809-10.846-2.226-16.631c0.338-3.344,0.678-6.593,1.855-9.741
		c0.718-1.919-0.383-4.21,1.234-5.985C128.291,250.417,128.369,249.887,129,250z"
      />
    </g>
  </svg>
);
