import { API } from "aws-amplify";

export const API_PATH = "Vili-API";

// Search
//------------------------------------------------------
export const search = async (entity, searchTerm) => {
  return API.post(API_PATH, `/${entity}/search`, {
    body: { searchTerm },
  });
};

export const getDetails = async (entities, id) => {
  return entities === "roofs"
    ? API.get(API_PATH, `/${entities}/${id}`)
    : API.get(API_PATH, `/${entities}/${id}/details`);
};

export const getLog = async (entities, id) => {
  return API.get(API_PATH, `/${entities}/${id}/log`);
};
// Site reports
//-----------------------------------------------------------
export const getExportDownloadUrl = async (siteId, token) => {
  return token === null
    ? API.get(API_PATH, `/roofs/${siteId}/reports/measurements-as-excel`)
    : API.get(
        API_PATH,
        `/roofs/${siteId}/reports/measurements-as-excel/${token}`
      );
};

export const loadSiteReport = async (siteId) => {
  return API.get(API_PATH, `/roofs/${siteId}/reports/device-report`);
};
