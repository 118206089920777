import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../Home";
import NotFound from "../NotFound";
import Login from "../Forms/Login";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnAuthenticatedRoute from "./UnAuthenticatedRoute";

export default ({ childProps }) => (
  <Switch>
    <UnAuthenticatedRoute
      path="/login"
      exact
      component={Login}
      props={childProps}
    />
    <AuthenticatedRoute path="/" component={Home} props={childProps} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
