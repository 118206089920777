import React from "react";
import { Button, Form, FormGroup, FormText, Input, Label } from "reactstrap";
import { useIntl } from "react-intl";
import "./forms.css";

const SearchForm = (props) => {
  const { formatMessage } = useIntl();
  const disabled = props.query && props.query.length > 3;
  return (
    <Form
      onSubmit={props.handleSubmit}
      className="search-form p-2 d-flex flex-row align-items-center justify-content-start"
    >
      <FormGroup className="d-flex flex-column text-left w-100 mt-2 mr-2">
        <Label tag="h6">{formatMessage({ id: props.label })}</Label>
        <Input
          bsSize="md"
          name={props.name}
          type="text"
          onChange={props.handleChange}
          maxLength={50}
          placeholder={formatMessage({ id: props.placeHolder })}
        />

        <FormText className="text-center text-muted">
          {formatMessage({ id: "search_minlength" })}
        </FormText>
        {/*<ClearInputButton query={props.query} onClear={() => props.onClear(props.name)} />*/}
      </FormGroup>

      <Button color="primary" type="submit" disabled={!disabled}>
        <h6>{formatMessage({ id: "search" })}</h6>
      </Button>
    </Form>
  );
};

export default SearchForm;
