import React from "react";
import { FaRss, FaExclamationTriangle } from "react-icons/fa";

export default ({ isOnline, isAlert }) => (
  <div className="status-indicator">
    {!isOnline ? (
      <FaRss id="status-indicator-offline" />
    ) : !isAlert ? (
      <FaRss id="status-indicator-online" />
    ) : (
      <FaExclamationTriangle id="status-indicator-danger" />
    )}
  </div>
);
