import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
  Fade,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import {
  Detail,
  DetailHeader,
  ErrorAlert,
  Loader,
  ResultTypes,
} from "../../Utilities";
import UserName from "./UserName";
import "./details.css";
import { withErrors, usePrevious } from "../../CustomHooks";
import ApiKeyDetails from "./ApiKeyDetails";
import { getDetails } from "../../Frontend-api";
import DetailsBackLink from "./DetailsBackLink";

const ListSites = ({
  sites,
  handleResultTypeChange,
  type,
  resultType,
  ...props
}) => {
  return (
    <ListGroupItem>
      <DetailHeader
        title={<FormattedMessage id={props.title} />}
        info={<FormattedMessage id={props.info} />}
      />
      {!sites || !sites.length ? (
        <ListGroupItemText className="text-muted">
          <FormattedMessage id={props.no_entities} />
        </ListGroupItemText>
      ) : (
        sites.map((item) => (
          <Detail
            key={item.id}
            detail={
              <Button
                className="devices-owned-btn"
                color="link"
                onClick={() =>
                  handleResultTypeChange(item.id, ResultTypes.TYPE_SITES)
                }
              >
                <p>{!item.name ? item.serial_number : item.name}</p>
              </Button>
            }
            middle={!item.name ? "" : item.serial_number}
          />
        ))
      )}
    </ListGroupItem>
  );
};

const ListOwnedGateways = ({ gateways_owned, handleResultTypeChange }) => {
  return (
    <ListGroupItem>
      <DetailHeader
        title={<FormattedMessage id="gateways_owned" />}
        info={<FormattedMessage id="details_info_gateways" />}
      />
      {!gateways_owned || !gateways_owned.length ? (
        <ListGroupItemText className="text-muted">
          <FormattedMessage id="no_gateways_owned" />
        </ListGroupItemText>
      ) : (
        gateways_owned.map((item) => (
          <Detail
            key={item.id}
            detail={
              <Button
                className="devices-owned-btn"
                color="link"
                onClick={() =>
                  handleResultTypeChange(
                    item.id,
                    ResultTypes.TYPE_GATEWAYS,
                    item.type
                  )
                }
              >
                <p>{!item.name ? item.serial_number : item.name}</p>
              </Button>
            }
            middle={!item.name ? "" : item.serial_number}
          />
        ))
      )}
    </ListGroupItem>
  );
};

const ListOwnedDevices = ({ devices_owned, handleResultTypeChange }) => {
  return (
    <ListGroupItem>
      <DetailHeader
        title={<FormattedMessage id="control_units_owned" />}
        info={<FormattedMessage id="details_info_devices" />}
      />
      {!devices_owned || !devices_owned.length ? (
        <ListGroupItemText className="text-muted">
          <FormattedMessage id="no_devices_owned" />
        </ListGroupItemText>
      ) : (
        devices_owned.map((item) => (
          <Detail
            key={item.id}
            detail={
              <Button
                className="devices-owned-btn"
                color="link"
                onClick={() =>
                  handleResultTypeChange(
                    item.id,
                    ResultTypes.TYPE_DEVICES,
                    item.type
                  )
                }
              >
                <p>{!item.name ? item.serial_number : item.name}</p>
              </Button>
            }
            middle={!item.name ? "" : item.serial_number}
          />
        ))
      )}
    </ListGroupItem>
  );
};

const ListOwnedGroups = ({ groups_owned }) => {
  return (
    <ListGroupItem>
      <DetailHeader
        title={<FormattedMessage id="groups_owned" />}
        info={<FormattedMessage id="details_info_groups" />}
      />
      {!groups_owned || !groups_owned.length ? (
        <ListGroupItemText className="text-muted">
          <FormattedMessage id="no_groups_owned" />
        </ListGroupItemText>
      ) : (
        groups_owned.map((item) => (
          <Detail
            key={item.id}
            detail={<ListGroupItemText>{item.name}</ListGroupItemText>}
          />
        ))
      )}
    </ListGroupItem>
  );
};

const ListLanguage = ({ settings }) => {
  return (
    <ListGroupItem>
      <DetailHeader
        title={<FormattedMessage id="locale" />}
        info={<FormattedMessage id="details_info_locale" />}
      />
      <Detail
        detail={
          !settings ? (
            <ListGroupItemText className="text-muted">
              <FormattedMessage id="no_locale" />
            </ListGroupItemText>
          ) : (
            <ListGroupItemText>
              <FormattedMessage id={`${settings.locale}`} />
            </ListGroupItemText>
          )
        }
      />
    </ListGroupItem>
  );
};

const UserDetails = ({
  userId,
  handleStatusChange,
  resultType,
  resultId,
  selectEntity,
  ...props
}) => {
  const [isLoading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const { errorText, handleErrors, toggleError, visible } = props;
  const previousId = usePrevious(userId);

  useEffect(() => {
    async function getItemDetails() {
      setLoading(true);
      try {
        const details = await getDetails("users", userId);
        setDetails(details);
      } catch (e) {
        handleErrors(e);
      } finally {
        setLoading(false);
      }
    }

    if (!userId) {
      return;
    } else if (previousId !== userId) {
      getItemDetails();
    }
  }, [userId, previousId, handleErrors]);

  const handleResultTypeChange = (id, type) => {
    selectEntity(id, type);
  };

  return (
    <Fragment>
      <ErrorAlert
        isOpen={visible}
        toggle={() => toggleError()}
        message={errorText}
      />
      {props.backResultType && (
        <DetailsBackLink
          backResultType={props.backResultType}
          handleResultTypeChange={handleResultTypeChange}
        />
      )}
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        details && (
          <Fade in={true}>
            <ListGroup>
              <UserName {...details} handleStatusChange={handleStatusChange} />
              <ListLanguage {...details} />
              <ApiKeyDetails {...details} update={setDetails} />
              <ListSites
                sites={details.sites_owned}
                title="sites_owned"
                info="sites_owned"
                no_entities="no_sites_owned"
                handleResultTypeChange={handleResultTypeChange}
                type="sites"
                resultType={resultType}
              />
              <ListOwnedGateways
                {...details}
                handleResultTypeChange={handleResultTypeChange}
                resultType={resultType}
              />
              <ListOwnedDevices
                {...details}
                handleResultTypeChange={handleResultTypeChange}
                resultType={resultType}
              />
              <ListOwnedGroups {...details} />{" "}
            </ListGroup>
          </Fade>
        )
      )}
    </Fragment>
  );
};

export default withErrors(UserDetails);
