import React from "react";
import { Button } from "reactstrap";
import { FormattedMessage } from "react-intl";

const CancelButton = (props) => (
  <Button
    className="cancel-btn mt-3"
    color="link"
    onClick={() => props.onClick()}
  >
    <h6>
      <FormattedMessage id="cancel" />
    </h6>
  </Button>
);

export default CancelButton;
