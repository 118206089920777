import React, { memo, Fragment, useState } from "react";
import { Card, Collapse } from "reactstrap";
import { useIntl } from "react-intl";
import { CustomCaret, Detail, StatusIndicator } from "../../../Utilities";
import LatestMeasurements from "./LatestMeasurements";

const Transmitter = ({
  id,
  name,
  serialNumber,
  isIndoorMaster,
  isOutdoorMaster,
  type,
  latestMeasurements,
  isOnline,
  isAlert
}) => {
  const { formatMessage } = useIntl();
  const [collapse, setCollapseOpen] = useState(false);

  return (
    <Fragment>
      <Detail
        id={serialNumber}
        style={{ cursor: "pointer" }}
        onClick={() => setCollapseOpen(!collapse)}
        status={<StatusIndicator isOnline={isOnline} isAlert={isAlert} />}
        detail={!name ? serialNumber : name}
        muted={
          isIndoorMaster
            ? formatMessage({ id: "transmitter_indoor" })
            : isOutdoorMaster
            ? formatMessage({ id: "transmitter_outdoor" })
            : ""
        }
        measurements={
          isOnline && <LatestMeasurements {...latestMeasurements} />
        }
        caret={<CustomCaret isOpen={collapse} />}
      />

      <Collapse isOpen={collapse}>
        <Card body className="transmitter-setting-card">
          <div className="d-flex flex-row">
            <div className="p-2 text-left ">
              <strong>{formatMessage({ id: "header_id" })}</strong>
            </div>
            <div className="p-2">{id}</div>
          </div>
          <div className="d-flex flex-row">
            <div className="p-2 text-left ">
              <strong>{formatMessage({ id: "header_serial" })}</strong>
            </div>
            <div className="p-2">{serialNumber}</div>
          </div>
          <div className="d-flex flex-row">
            <div className="p-2 text-left ">
              <strong>{formatMessage({ id: "header_type" })}</strong>
            </div>
            <div className="p-2">{type}</div>
          </div>

          <div className="d-flex flex-row">
            <div className="p-2 text-left ">
              <strong>{formatMessage({ id: "header_status" })}</strong>
            </div>
            <div className="p-2">
              {formatMessage({
                id: `status_${
                  !isOnline ? "offline" : !isAlert ? "online" : "isAlert"
                }`
              })}
            </div>
          </div>
        </Card>
      </Collapse>
    </Fragment>
  );
};

export default memo(Transmitter);
