import React, { memo } from "react";
import { ListGroupItem } from "reactstrap";
import { FormattedMessage } from "react-intl";
import Transmitter from "./Transmitter";
import { DetailHeader } from "../../../Utilities";

const Transmitters = ({ transmitters }) => {
  return (
    <ListGroupItem>
      <DetailHeader
        title={<FormattedMessage id="header_transmitters" />}
        info={<FormattedMessage id="header_transmitters" />}
      />
      {transmitters &&
        transmitters.map(({ id, ...attributes }, index) => (
          <Transmitter key={index} id={id} {...attributes} />
        ))}
    </ListGroupItem>
  );
};

export default memo(Transmitters);
