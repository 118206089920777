import React, { memo, useState } from "react";
import useForm, { useErrorHandling } from "../CustomHooks";
import { useIntl } from "react-intl";
import SearchForm from "../Forms/SearchForm";
import { ErrorAlert, Loader, ResultTypes } from "../Utilities";
import "./MainMenuContent.css";
import { ReportButton } from "../Buttons";
import Results from "./Results";
import { search } from "../Frontend-api";

const SearchDevices = ({ setResults, results, setTerm, searchTerm }) => {
  const { formatMessage } = useIntl();
  const [isLoading, setLoading] = useState(false);

  const { errorText, handleErrors, toggleError, visible } = useErrorHandling();

  const queryDevices = async () => {
    let query = inputs.deviceQuery;
    setLoading(true);
    setTerm(query);

    try {
      const response = await search("devices", query);
      setResults(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleErrors(e);
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(queryDevices);
  return (
    <div className="search-content">
      <div className="d-flex flex-column justify-content-center mx-auto w-50">
        <h3 className="mb-5">{formatMessage({ id: "device_management" })}</h3>
        <ErrorAlert
          isOpen={visible}
          toggle={() => toggleError()}
          message={errorText}
        />
        <div className="d-flex justify-content-center">
          <ReportButton title="devices" />
        </div>

        <SearchForm
          name="deviceQuery"
          handleSubmit={handleSubmit}
          handleChange={handleInputChange}
          label="search_devices_title"
          placeHolder="search_devices_by"
          query={inputs.deviceQuery}
        />
      </div>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <div className="search-results">
          <Results
            searchTerm={searchTerm}
            resultType={ResultTypes.TYPE_DEVICES}
            results={results}
          />
        </div>
      )}
    </div>
  );
};

export default memo(SearchDevices);
