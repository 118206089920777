import React, { useState } from "react";
import { Button } from "reactstrap";
import ReportIcon from "../img/icons/ReportIcon";
import SyncIcon from "../img/icons/SyncIcon";

import { API } from "aws-amplify";

export default ({ details, ...props }) => {
  const [isLoading, setLoading] = useState(false);
  const { handleErrors } = props;

  // -- Raportin lataus
  const download = (data) => {
    const { name, serialNumber } = details;
    const url = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${!name ? serialNumber : name}.xlsx`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // -- Raporttinapin painallus
  const handleReportDownload = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { id } = details;
      const request = {
        headers: {
          accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
      };
      const result = await API.get(
        "Vili-API",
        `/devices/${id}/details/excel`,
        request
      );

      download(result);
    } catch (e) {
      handleErrors(e);
    } finally {
      setLoading(false);
    }
  };

  // -- Näytetään spinneri, jos raporttinappia on painettu ja suoritus on käynnissä,
  // -- muuten raporttikuvake
  return (
    <Button
      className="detail-report-btn"
      color="link"
      onClick={(e) => handleReportDownload(e)}
    >
      {isLoading ? (
        <div>
          <SyncIcon className="btn-spin" />
        </div>
      ) : (
        <ReportIcon className="report-icon" />
      )}
    </Button>
  );
};
