import React from "react";

export default ({ className, fill, ovalFill }) => (
  <svg
  className={className}
    width="40px"
    height="36px"
    viewBox="0 0 40 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>B751028C-F654-471F-88D7-7FBE9DB21B5D</title>
    <desc>Created with sketchtool.</desc>
    <g
      id="Mobile"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="icons" transform="translate(-895.000000, -259.000000)">
        <g
          id="icon-example-report"
          transform="translate(895.000000, 259.000000)"
        >
          <g id="file-regular" fill={fill} fillRule="nonzero">
            <path
              d="M26.0085938,6.88927944 L20.109375,0.991212654 C19.4765625,0.358523726 18.61875,0 17.7257813,0 L3.375,0 C1.51171875,0.00702987698 0,1.51845343 0,3.38137083 L0,32.6256591 C0,34.4885764 1.51171875,36 3.375,36 L23.625,36 C25.4882813,36 27,34.4885764 27,32.6256591 L27,9.27943761 C27,8.38664323 26.6414063,7.52196837 26.0085938,6.88927944 Z M23.3507813,9.00527241 L18,9.00527241 L18,3.65553603 L23.3507813,9.00527241 Z M3.375,32.6256591 L3.375,3.38137083 L14.625,3.38137083 L14.625,10.6924429 C14.625,11.6274165 15.3773438,12.3796134 16.3125,12.3796134 L23.625,12.3796134 L23.625,32.6256591 L3.375,32.6256591 Z"
              id="Shape"
            ></path>
          </g>
          <g
            id="search-plus-regular"
            transform="translate(15.000000, 10.000000)"
          >
            <ellipse
              id="Oval"
              fill={ovalFill}
              cx="11.71875"
              cy="11.8181818"
              rx="11.71875"
              ry="11.8181818"
            ></ellipse>
            <path
              d="M15.8440292,11.41149 L15.8440292,12.5193905 C15.8440292,12.8240631 15.5968489,13.0733407 15.2947396,13.0733407 L12.1820987,13.0733407 L12.1820987,16.212392 C12.1820987,16.5170646 11.9349183,16.7663422 11.6328091,16.7663422 L10.5342299,16.7663422 C10.2321206,16.7663422 9.98494031,16.5170646 9.98494031,16.212392 L9.98494031,13.0733407 L6.87229933,13.0733407 C6.57019005,13.0733407 6.32300974,12.8240631 6.32300974,12.5193905 L6.32300974,11.41149 C6.32300974,11.1068174 6.57019005,10.8575398 6.87229933,10.8575398 L9.98494031,10.8575398 L9.98494031,7.71848853 C9.98494031,7.41381591 10.2321206,7.16453831 10.5342299,7.16453831 L11.6328091,7.16453831 C11.9349183,7.16453831 12.1820987,7.41381591 12.1820987,7.71848853 L12.1820987,10.8575398 L15.2947396,10.8575398 C15.5968489,10.8575398 15.8440292,11.1068174 15.8440292,11.41149 Z M24.8386462,24.7940042 L23.8041508,25.8372771 C23.5890124,26.054241 23.241129,26.054241 23.0259906,25.8372771 L17.4690109,20.2331474 C17.3637304,20.1269736 17.3088015,19.988486 17.3088015,19.8407659 L17.3088015,19.2314207 C15.6380456,20.68554 13.4637744,21.5672441 11.0835195,21.5672441 C5.8240717,21.5672441 1.5625,17.2695137 1.5625,11.9654403 C1.5625,6.66136685 5.8240717,2.36363636 11.0835195,2.36363636 C16.3429673,2.36363636 20.604539,6.66136685 20.604539,11.9654403 C20.604539,14.3658912 19.730253,16.5586109 18.2883679,18.2435428 L18.8925864,18.2435428 C19.0390636,18.2435428 19.176386,18.3035541 19.2816665,18.4051116 L24.8386462,24.0092414 C25.0537846,24.2262052 25.0537846,24.5770404 24.8386462,24.7940042 Z M18.4073806,11.9654403 C18.4073806,7.8846736 15.1299528,4.57943726 11.0835195,4.57943726 C7.0370862,4.57943726 3.75965834,7.8846736 3.75965834,11.9654403 C3.75965834,16.0462069 7.0370862,19.3514432 11.0835195,19.3514432 C15.1299528,19.3514432 18.4073806,16.0462069 18.4073806,11.9654403 Z"
              id="Shape"
              fill={fill}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
