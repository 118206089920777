import React, { memo, Fragment, useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
//import { last, takeRight } from "lodash";

const setHouseStatus = (status) => {
  const { isOnline, isAlert } = status;
  return !isOnline ? "offline" : !isAlert ? "ok" : "alert";
};

const setMoldIndexStatus = (status) => {
  const { isOnline, isAlert } = status;
  return !isOnline ? "secondary" : isAlert ? "danger" : "success";
};

const setPurpose = (purpose) => {
  return purpose === "roof" ? "roof" : purpose === "interior" ? "room" : "base";
};

/*const setFanSpeed = ({ values, highLimit, lowLimit }) => {
  let rpm = last(values).value;

  return !(rpm > lowLimit)
    ? "0"
    : !(rpm > Math.ceil(highLimit * 0.2))
    ? "1"
    : !(rpm > Math.ceil(highLimit * 0.4))
    ? "2"
    : !(rpm > Math.ceil(highLimit * 0.6))
    ? "3"
    : !(rpm > Math.ceil(highLimit * 0.8))
    ? "4"
    : "5";
};
*/

const Usage = ({ data, status, purpose }) => {
  const [moldIndex, setMoldIndex] = useState({});
  const [indoorHumidity, setIndoorHumidity] = useState({});
  const [outdoorHumidity, setOutdoorHumidity] = useState({});

  useEffect(() => {
    function setValues(data) {
      setMoldIndex(data[1]);
      setIndoorHumidity(data[4]);
      setOutdoorHumidity(data[5]);
    }

    if (data) {
      setValues(data);
    }
  });

  return (
    <div className="d-flex justify-content-center">
      <div id="house-bg">
        <Row className="mt-4 mb-3">
          <Col size={6} className="d-flex justify-content-center">
            <div
              className={`house-container house-${setPurpose(purpose)} status-${
                !status ? "offline" : setHouseStatus(status)
              }`}
            >
              <div className="house ml-3">
                {data && (
                  <Fragment>
                    <div className="outside-humidity">
                      <b>
                        {!outdoorHumidity
                          ? "--"
                          : !outdoorHumidity.value
                          ? "--"
                          : `${outdoorHumidity.value + outdoorHumidity.unit}`}
                      </b>
                    </div>
                    <div className="inside-humidity">
                      <b>
                        {!indoorHumidity
                          ? "--"
                          : !indoorHumidity.value
                          ? "--"
                          : `${indoorHumidity.value + indoorHumidity.unit}`}
                      </b>
                    </div>
                  </Fragment>
                )}
              </div>
              <div className="house-status-roof">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119 41">
                  <polygon points="0,30.3 60.3,0 119,27.8 119,41 1.6,41 " />
                </svg>
              </div>
              <div className="house-status-room">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119 50">
                  <rect width="300" height="120" />
                </svg>
              </div>
              <div className="house-status-base">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119 41">
                  <rect width="300" height="27" />
                </svg>
              </div>
            </div>
          </Col>
          <Col size={6} className="d-flex justify-content-center">
            <div className="mold-index">
              <div>
                <span
                  className={`status status-big bg-${
                    !status ? "" : setMoldIndexStatus(status)
                  }`}
                />
                <div className="mold-index-number">
                  {data && !moldIndex.value ? 0 : moldIndex.value}
                </div>
              </div>
              <div className="mold-index-label">
                <FormattedMessage id="mold_index" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default memo(Usage);
