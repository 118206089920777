import React from "react";

export default ({ className, fill }) => 
<svg className={className} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>04552AAA-8C26-4B20-BBAD-F1FF53520B62</title>
    <desc>Created with sketchtool.</desc>
    <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="icons" transform="translate(-1028.000000, -206.000000)" fill={fill} fillRule="nonzero">
            <g id="icon-sync" transform="translate(1028.000000, 206.000000)">
                <path d="M15.3391935,0.660806452 L13.6564516,2.34354839 C12.208871,0.895709677 10.2091935,0 8,0 C3.71703226,0 0.220129032,3.36574194 0.01,7.59654839 C-0.000935483871,7.81648387 0.176612903,8 0.396806452,8 L1.30148387,8 C1.50641935,8 1.67609677,7.84025806 1.68770968,7.63567742 C1.87616129,4.31125806 4.62751613,1.67741935 8,1.67741935 C9.74719355,1.67741935 11.3276452,2.3846129 12.4714194,3.52858065 L10.7253548,5.27467742 C10.4814839,5.51854839 10.6541935,5.93548387 10.9990645,5.93548387 L15.6129032,5.93548387 C15.8266774,5.93548387 16,5.76216129 16,5.5483871 L16,0.934548387 C16,0.589677419 15.5830323,0.416967742 15.3391935,0.660806452 Z M15.6031935,8 L14.6985161,8 C14.4935806,8 14.3239032,8.15974194 14.3122903,8.36432258 C14.1238387,11.6887419 11.3724839,14.3225806 8,14.3225806 C6.25280645,14.3225806 4.67235484,13.6153871 3.52858065,12.4714194 L5.27464516,10.7253226 C5.51851613,10.4814516 5.34580645,10.0645161 5.00093548,10.0645161 L0.387096774,10.0645161 C0.173322581,10.0645161 0,10.2378387 0,10.4516129 L0,15.0654516 C0,15.4103226 0.416967742,15.5830323 0.660806452,15.3391613 L2.34354839,13.6564516 C3.79112903,15.1042903 5.79083871,16 8,16 C12.283,16 15.779871,12.6342581 15.99,8.40345161 C16.0009355,8.18351613 15.8233871,8 15.6031935,8 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>