import React, { memo, useState, useEffect, useCallback } from "react";
import useForm, { useErrorHandling } from "../CustomHooks";
import { useIntl } from "react-intl";
import SearchForm from "../Forms/SearchForm";
import Results from "./Results";
import { ErrorAlert, Loader, ResultTypes } from "../Utilities";
import "./MainMenuContent.css";
import { ReportButton } from "../Buttons";
import AddUser from "../Forms/AddUser";
import { search } from "../Frontend-api";

const SearchUsers = ({ setResults, results, setTerm, searchTerm }) => {
  const { formatMessage } = useIntl();
  const [isLoading, setLoading] = useState(false);
  const [statusChanged, setStatusChange] = useState("");
  const { errorText, handleErrors, toggleError, visible } = useErrorHandling();

  const handleStatusChange = useCallback((status) => {
    setStatusChange(status);
  }, []);

  useEffect(() => {
    async function getChangedData() {
      setLoading(true);
      try {
        const response = await search("users", searchTerm);
        setResults(response);
      } catch (e) {
        handleErrors(e);
      }
      setStatusChange("");
      setLoading(false);
    }

    if (statusChanged !== "" && searchTerm !== "") {
      getChangedData(searchTerm);
    }
  }, [setResults, statusChanged, searchTerm, handleErrors]);

  const queryUsers = async (email) => {
    let query = email ? email : inputs.userQuery;
    setTerm(query);
    setLoading(true);

    try {
      const response = await search("users", query);
      setResults(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleErrors(e);
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(queryUsers);
  return (
    <div className="search-content">
      <div className="d-flex flex-column justify-content-center mx-auto w-50">
        <h3 className="mb-5">{formatMessage({ id: "user_management" })}</h3>
        <ErrorAlert
          isOpen={visible}
          toggle={() => toggleError()}
          message={errorText}
        />
        <div className="d-flex flex-row align-items-center justify-content-center p-2">
          <AddUser search={queryUsers} />
          <ReportButton title="users" />
        </div>

        <SearchForm
          name="userQuery"
          handleSubmit={handleSubmit}
          handleChange={handleInputChange}
          label="search_users_title"
          placeHolder="search_users_by"
          query={inputs.userQuery}
        />
      </div>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <div className="search-results">
          <Results
            searchTerm={searchTerm}
            resultType={ResultTypes.TYPE_USERS}
            results={results}
            handleStatusChange={handleStatusChange}
          />
        </div>
      )}
    </div>
  );
};

export default memo(SearchUsers);
