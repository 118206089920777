import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ResultTypes from "./ResultTypes";
import { toSimpleDeviceType } from "./DeviceTypes";

const useSelectedEntity = (resultType, resultId, deviceType) => {
  const [selectedEntity, setSelectedEntity] = useState(undefined);

  const history = useHistory();

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const site = params.get("sites");
  const device = params.get("devices");
  const gateway = params.get("gateways");
  const user = params.get("users");

  useEffect(() => {
    if (site) {
      setSelectedEntity({ siteId: site, type: ResultTypes.TYPE_SITES });
    } else if (device) {
      setSelectedEntity({ deviceId: device, type: ResultTypes.TYPE_DEVICES });
    } else if (gateway) {
      setSelectedEntity({
        gatewayId: gateway,
        type: ResultTypes.TYPE_GATEWAYS,
      });
    } else if (user) {
      setSelectedEntity({ userId: user, type: ResultTypes.TYPE_USERS });
    } else {
      setSelectedEntity(undefined);
    }
  }, [site, device, gateway, user]);

  const selectEntity = (id, type) => {
    const basePath = deviceType
      ? `/devices/${toSimpleDeviceType(deviceType)}/`
      : `/${resultType}/`;

    if (!id) {
      history.push(basePath + resultId);
    } else if (type) {
      history.push(basePath + resultId + `?${type}=${id}`);
    }
  };

  return [selectedEntity, selectEntity];
};

export default useSelectedEntity;
