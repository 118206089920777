import React, { Fragment, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { InfoButton } from "../Buttons";
import { FormattedMessage } from "react-intl";
import "./Utilities.css";

export default ({ children }) => {
  const [on, setModalState] = useState(false);
  const toggle = () => {
    setModalState(!on);
  };

  return (
    <Fragment>
      <InfoButton onClick={() => toggle()} />
      <Modal
        isOpen={on}
        toggle={toggle}
        className="confirmation-dialog text-center"
        backdrop={false}
      >
        {" "}
        <ModalBody>{children}</ModalBody>
        <Button block onClick={() => toggle()} color="primary">
          <h6>
            <FormattedMessage id="close" />
          </h6>
        </Button>
      </Modal>
    </Fragment>
  );
};
