const fi = {
  details_info_username:
    "Käyttäjän tunniste sekä tieto, onko tunniste käytössä vai ei.",
  details_info_locale: "Käyttäjän valitsema lokalisaatio kättöliittymässä.",
  details_info_devices: "Käyttäjän omistamat laitteet ja niiden sarjanumerot.",
  details_info_groups: "Käyttäjän luomat ryhmät.",
  details_info_publiclink:
    "Käyttäjän luomat julkiset linkit laitteille ja ryhmille.",
  device: "Laite",
  email_placeholder: "sähköposti",
  password_placeholder: "salasana",
  password_forgot: "Unohditko salasanasi?",
  login: "Kirjaudu sisään",
  login_loading: "Kirjaudutaan...",
  login_title: "Hallinnointi",
  logout: "Kirjaudu ulos",
  logoutConfirm: "Haluatko varmasti kirjautua ulos?",
  auth_err_login: "Käyttäjätunnus tai salasana ei kelpaa.",
  InvalidParameterException: "Käyttäjänimeä ei ole vahvistettu.",
  NotAuthorizedException: "Käyttäjänimi tai salasana ei kelpaa.",
  LimitExceededException: "Liian monta yritystä_ Koita myöhemmin uudelleen.",
  UserNotFoundException:
    "Käyttäjätunnus ei kelpaa. Tarkista, että kirjoitit sähköpostisi oikein.",
  UserNotConfirmedException:
    "Käyttäjätiliä ei ole vahvistettu. Vahvista tili, jotta voit kirjautua sisään.",
  error_user_exists: "Käyttäjä on jo olemassa.",
  server_error_no_user: "Virhe palvelimella: Käyttäjää ei ole olemassa.",
  error_user_email_not_verified:
    "Syöttämääsi sähköpostiosoitetta ei ole varmistettu AWS Cognitossa. Varmista osoite tai ota yhteyttä ylläpitoon.",
  error_invalid_email_format:
    "Varmista, että sähköposti on oikein kirjoitettu.",
  error_api_already_enabled:
    "API-avain on jo otettu käyttöön kyseisellä käyttäjällä.",
  error_api_already_disabled:
    "API-avain on jo poistettu käytöstä kyseiseltä käyttäjältä.",
  error_admin_cannot_be_modified:
    "Käyttäjää ei voida muokata, koska käyttäjä on admin.",
  Error: "Jotakin meni pieleen.",
  error_main: "Jotakin meni pieleen.",
  error_400: "Syötä hakutermi",
  error_401: "Sinun täytyy olla kirjautuneena sisään.",
  error_403: "Ei käyttöoikeutta",
  error_404: "Sivua tai resurssia ei löydy.",
  error_500: "Jotakin meni pieleen palvelimella.",
  error_504: "Palvelin ei vastaa. Kokeile päivittää sivu.",
  auth_username_missing:
    "Käyttäjätunnus puuttuu. Palaa takaisin ja täytä kirjautumistietosi uudelleen.",
  auth_code_missing: "Varmistuskoodi puuttuu.",
  auth_code_mismatch: "Varmistuskoodi ei kelpaa.",
  page_not_found: "Pahoittelut, sivua ei löydy!",
  main_nav_devices: "Laitteet",
  main_nav_users: "Käyttäjät",
  main_nav_sites: "Kohteet",
  main_nav_reports: "Raportointi",
  search: "Hae",
  search_minlength: "Kirjoita vähintään 4 merkkiä",
  results_with_term: "Tulokset hakusanalla: ",
  no_hits: "Ei hakutuloksia",
  search_devices_title: "Etsi laitteita",
  search_devices_by: "Etsi nimeä tai sarjanumeroa",
  search_users_title: "Etsi käyttäjiä",
  search_users_by: "Etsi käyttäjänimellä",
  search_sites_title: "Etsi kohteita",
  search_sites_by: "Etsi kohteen nimellä",
  header_id: "ID",
  header_serial: "Sarjanumero",
  header_serialNumber: "Sarjanumero",
  header_owner: "Omistaja",
  header_name: "Tunniste",
  header_identifier: "Tunniste",
  header_email: "Sähköposti",
  header_status: "Tila",
  header_publicLink: "Julkinen linkki",
  header_group: "Ryhmä",
  header_type: "Tyyppi",
  header_deviceDetails: "Laitteen tiedot",
  header_siteDetails: "Kohteen tiedot",
  header_transmitters: "Lähettimet",
  header_purpose: "Käyttötarkoitus",
  header_constructionMaterial: "Rakennusmateriaali",
  header_rpm: "Rpm",
  header_fan_rpm: "Rpm",
  header_moldIndex: "Homeindeksi",
  header_mold_index: "Homeindeksi",
  header_temperature: "T",
  header_humidity: "H",
  header_latest_moldIndex: "Viimeisin homeindeksi",
  header_latest_rpm: "Viimeisin rpm",
  header_log: "Tapahtumaloki",
  header_devices_owned: "Omistetut laitteet",
  header_indoor_name: "Sis.läh. tunniste",
  header_indoor_serial: "Sis.läh. sarjanumero",
  header_indoor_timestamp: "Sis.läh. aikaleima",
  header_indoor_temperature: "Sis.läh. °C",
  header_indoor_humidity: "Sis.läh. %",
  header_outdoor_name: "Ulk.läh. tunniste",
  header_outdoor_serial: "Ulk.läh. sarjanumero",
  header_outdoor_timestamp: "Ulk.läh. aikaleima",
  header_outdoor_temperature: "Ulk.läh. °C",
  header_outdoor_humidity: "Ulk.läh. %",
  header_extra: "Muut lähettimet",
  header_timestamp: "Aikaleima",
  header_reports: "Raportit",
  header_gateway_latestCommunicationTime: "Viimeisin yhteydenotto",
  header_gateway_configurationUpdateTime: "Konfiguraatio päivitetty",
  header_mcu_transmitterConfigurationVersions: "Lähetinkonfiguraatioversiot",
  header_mcu_transmitterConfigurationVersions_info:
    "Tietoa lähetinkonfiguraatioversioista",
  header_version: "Versio",
  header_versionOnDevice: "Versio laitteella",
  header_createTime: "Luotu",
  header_retryCounter: "Uudelleenlähetysten määrä",
  header_configuration_status: "Tila",
  header_timeAcknowledged: "Kuittauksen aikaleima",
  header_timePutToQueue: "Asetettu jonoon",
  header_timeRemovedFromQueue: "Poistettu jonosta",
  header_timeShouldBeSent: "Lähetysaika",
  status_should_be_sent: "Tulisi lähettää",
  status_abandoned: "Hylätty",
  status_acknowledged: "Kuitattu",
  status_waiting: "Odottaa",
  status_put_to_queue: "Jonossa",
  status_removed_from_queue: "Poistettu jonosta",
  additionalTransmitters: "Lähettimet:",
  no_additionalTransmitters: "Ei lähettimiä:",
  transmitter_indoor: "Ohjaava sisälähetin",
  transmitter_outdoor: "Ohjaava ulkolähetin",
  transmitter_extra: "Lisälähetin",
  publicLink: "Julkinen linkki:",
  no_publicLink: "Ei julkista linkkiä:",
  users: "Käyttäjät",
  no_users: "Ei lisättyjä käyttäjiä",
  more: "Lisää...",
  locale: "Kielivalinta",
  no_locale: "Ei valittua kieltä",
  no_given_identifier: "Ei annettua tunnistetta",
  no_connected_devices: "Ei liitetyjä ohjausyksiköitä",
  devices_connected: "Liitetyt laitteet",
  details_info_connected_devices: "Tukiasemaan liitetyt MCU-2 ohjausyksiköt",
  gateways_owned: "Omistetut tukiasemat",
  details_info_gateways: "Käyttäjän omistamat tukiasemat",
  no_gateways_owned: "Ei tukiasemia",
  devices_owned: "Omistetut laitteet",
  control_units_owned: "Omistetut ohjausyksiköt",
  no_devices_owned: "Ei laitteita",
  groups_owned: "Omistetut ryhmät",
  no_groups_owned: "Ei ryhmiä",
  sites_owned: "Omistetut kohteet",
  no_sites_owned: "Ei kohteita",
  username: "Käyttäjätunnus",
  no_username: "Käyttäjätunnusta ei löydy",
  status: "Käyttäjän status",
  status_undefined: "Ei määriteltyä tilaa",
  status_enabled: "Käytössä",
  status_disabled: "Poistettu käytöstä",
  status_online: "Toiminnassa",
  status_offline: "Ei yhteyttä",
  status_isAlert: "Hälytys",
  user_activate: "Aktivoi",
  user_deactivate: "Deaktivoi",
  user_delete: "Poista",
  user_deleting: "Poistetaan...",
  user_delete_confirm: "Haluatko varmasti poistaa käyttäjän {user_id}?",
  en: "Englanti",
  fi: "Suomi",
  sv: "Ruotsi",
  ru: "Venäjä",
  pl: "Puola",
  cancel: "Peruuta",
  close: "Sulje",
  back_to_ccu: "Takaisin tukiaseman tietoihin",
  back_to_user: "Takaisin käyttäjän tietoihin",
  back_to_mcu: "Takaisin ohjausyksikön tietoihin",
  back_to_site: "Takaisin kohteen tietoihin",
  report_users: "Luo raportti kaikista käyttäjistä",
  report_devices: "Luo raportti kaikista laitteista",
  report_deviceDetails: "Vie raporttiin",
  report_measurements: "Mittausdatan raportti",
  report_site_devices: "Kohteen laiteraportti",
  or: "tai",
  measured: "Viimeisin mittaus: ",
  no: "Ei",
  yes: "Kyllä",
  concrete: "Betoni",
  planed_timber: "Höylätty puutavara",
  sawn_timber: "Sahattu puutavara",
  purpose_roof: "Kattorakenteen tuuletus",
  purpose_base_floor: "Ryömintätilan tuuletus",
  purpose_interior: "Sisätilan tuuletus",
  mold_index: "Homeindeksi",
  created: "Kirjannut: {email}.",
  not_available: "Ei saatavilla",
  home_title: "VILPE Sense hallinnointi",
  home_search_guide:
    "Näillä sivuilla voit etsiä ja seurata tietoa järjestelmään rekisteröidyistä käyttäjistä sekä laitteista.",
  from_active: "aktiivisesta",
  to_active: "aktiiviseksi",
  from_deactive: "deaktivoidusta",
  to_deactive: "deaktivoiduksi",
  to_deleted: "poistetuksi",
  from_deleted: "poistetusta",
  user_created: "Käyttäjä {user_id} luotu.",
  user_status_changed: "Tila muutettu {old_status} {new_status}.",
  user_password_changed: "Salasana vaihdettu.",
  user_settings_changed: "Kieliasetus muutettu.",
  device_registered: "Uusi laite rekisteröity järjestelmään.",
  device_name_changed: "Laitteen tunniste muutettiin.",
  device_public_link_identifier_updated:
    "Julkisen linkin uusi tunniste on {new_public_link_identifier}.",
  device_user_added: "Käyttäjä {user_id} lisätty laitteelle.",
  device_user_deleted: "Käyttäjä {user_id} poistettu laitteelta.",
  device_ownership_abandoned: "Omistajuus luovutettu.",
  device_added_to_group: "Laite lisätty ryhmään {group_name}.",
  device_removed_from_group: "Laite poistettu ryhmästä {parent_group_name}.",
  device_alarm_on: "Hälytys päällä.",
  device_offline:
    "Laite pois päältä. Viestejä ei ole tullut viimeiseen 24 tuntiin.",
  device_offline_off: "Laite päällä.",
  transmitter_offline:
    "Lähetin pois päältä. Arvoja ei ole tullut viimeiseen 24 tuntiin.",
  transmitter_offline_off: "Lähetin päällä.",
  high_mold_index: "Homeindeksi on yli 2.5",
  high_mold_index_off: "Homeindeksi alle 2.5",
  low_temperature:
    "Lämpötilan alarajan hälytys aktivoitui {value}°C/{limit}°C.",
  low_temperature_off: "Lämpötilan alarajan hälytys poistui",
  high_temperature:
    "Lämpötilan ylärajan hälytys aktivoitui {value}°C/{limit}°C.",
  high_temperature_off: "Lämpötilan ylärajan hälytys poistui.",
  low_humidity: "Kosteuden ylärajan hälytys aktivoitui {value}%/{limit}%.",
  low_humidity_off: "Kosteuden ylärajan hälytys poistui.",
  high_humidity: "Kosteuden ylärajan hälytys aktivoitui {value}%/{limit}%.",
  high_humidity_off: "Kosteuden ylärajan hälytys poistui.",
  low_battery: "Lähettimen pariston taso alle 20%.",
  low_battery_off: "Lähettimen pariston taso hyvä.",
  device_alarm_off: "Hälytys {alert_id} poistunut.",
  device_ownership_requested: "Omistajuutta pyydetty sähköpostitse.",
  device_mold_index_alarm_setting_changed:
    "Homeindeksin hälytyksen asetus muutettu.",
  group_created: "Ryhmä {group_name} luotu.",
  group_name_changed: "Ryhmä {group_id}: Ryhmän uusi nimi on {new_group_name}.",
  group_public_link_identifier_updated:
    "Ryhmä {group_id}: Ryhmän julkisen linkin uusi tunniste on {new_public_link_identifier}.",
  group_user_added: "Ryhmä {group_id}: Käyttäjä {user_id} lisätty.",
  group_user_deleted: "Ryhmä {group_id}: Käyttäjä {user_id} poistettu.",
  group_added_to_group:
    "Ryhmä {group_name} siirretty ryhmään {parent_group_name}.",
  group_removed_from_group:
    "Ryhmä {group_name} poistettu ryhmästä {parent_group_name}.",
  group_deleted: "Ryhmä {group_id} poistettu.",
  transmitter_created: "Lähetin luotu järjestelmään",
  transmitter_name_changed: "Lähettimen tunniste muutettiin.",
  transmitter_set_as_outdoor_master:
    "Lähetin asetettu ohjaavaksi ulkolähettimeksi.",
  transmitter_set_as_indoor_master:
    "Lähetin asetettu ohjaavaksi sisälähettimeksi.",
  transmitter_online: "Lähetin päällä.",
  transmitter_attached_to_device: "Lähetin liitetty laitteeseen.",
  transmitter_detached_from_device: "Lähetin irrotettu laitteesta.",
  limit_temperature_high: "Lämpötilahälytyksen yläraja asetettu: {limit}°C.",
  limit_temperature_high_off: "Lämpötilahälytyksen yläraja poistettu käytöstä.",
  limit_temperature_low: "Lämpötilahälytyksen alaraja asetettu: {limit}°C.",
  limit_temperature_low_off: "Lämpötilahälytyksen alaraja poistettu käytöstä.",
  limit_humidity_high: "Kosteushälytyksen yläraja asetettu: {limit}%.",
  limit_humidity_high_off: "Kosteushälytyksen yläraja poistettu käytöstä.",
  limit_humidity_low: "Kosteushälytyksen alaraja asetettu: {limit}%.",
  limit_humidity_low_off: "Kosteushälytyksen alaraja poistettu käytöstä.",
  transmitter_alarm_limits_changed:
    "Lähettimen hälytysrajoja muutettu. {alarm_limits}",
  relative_humidity_alert_delay: "Suhteellisen kosteuden hälytysviive.",
  temperature_alert_delay: "Lämpötilan hälytysviive.",
  user_note: "{note_content}",
  public_link_identifier_deleted: "Julkinen linkki poistettiin.",
  gateway_registered: "Uusi tukiasema rekisteröitiin.",
  gateway_name_changed: "Tukiaseman tunniste muutettiin {new_gateway_name}.",
  gateway_ownership_abandoned: "Tukiaseman omistajuus luovutettiin.",
  gateway_ownership_requested:
    "Tukiaseman omistajuutta pyydetty sähköpostitse.",
  gateway_offline:
    "Tukiasema pois päältä. Viestejä ei ole tullut viimeiseen 24 tuntiin.",
  gateway_offline_off: "Gateway päällä.",
  device_ownership_moved: "Laitteen omistajuus siirretty",
  add_user: "Kutsu käyttäjä",
  user_management: "Käyttäjien hallinta",
  device_management: "Laitehallinta",
  site_management: "Kohteiden hallinta",
  invite_user: "Kutsu käyttäjä",
  invite: "Kutsu",
  inviting: "Lähetetään kutsua..",
  user_added_success: "Käyttäjän kutsu onnistui.",
  api_key_header: "Kolmannen osapuolen sovellukset",
  api_key_info: "Tietoa käyttäjän API-avaimen tilasta",
  api_key_status: "API-avaimen tila",
  api_key_generated: "API-avain luotu",
  api_key_enable: "Ota käyttöön",
  api_key_enabled: "Käytössä",
  api_key_enable_confirm: "Ota API-avain käyttöön?",
  api_key_enabling: "Otetaan käyttöön..",
  api_key_disable: "Poista",
  api_key_disabled: "Pois käytöstä",
  api_key_disabling: "Poistetaan...",
  api_key_disable_confirm: "Poista API-avain käytöstä?",
  api_key_last_used: "Viimeksi käytetty:",
  api_key_last_used_never: "Ei koskaan",
  device_api_access_changed: "Laitteen API saatavuus muutettu",
  site_data_export: "Mittausdatan raportti",
  site_data_export_title: "Luodaan raporttia",
  site_data_export_download: "Tallenna",
  site_data_export_expired: "Latauslinkki on vanhentunut",
  site_data_export_error: "Virhe raportin luomisessa",
  view_site_info:
    "Varmista, että olet kirjautunut samoilla tunnuksilla myös peruskäyttöliitymään.",
  site_report_not_included_in_site: "Ei sisälly tähän kohteeseen",
  sites_connected: "Kohteet",
  details_info_connected_sites:
    "Kohteet, joissa tukiasema on käytössä joko itse asennettuna tai johon liitetty anturi on asennettuna.",
  no_connected_sites: "Ei liittyviä kohteita.",
  installed_to_site: "Asennettu kohteeseen.",
  invalid_temperature_value:
    "Anturin lämpötilamittausarvo on epäkelpo tai mittausalueen ulkopuolella.",
  invalid_temperature_value_off:
    "Anturin lämpötilamittausarvo normalisoitunut.",
  invalid_humidity_value:
    "Anturin kosteusmittausarvo on epäkelpo tai mittausalueen ulkopuolella.",
  invalid_humidity_value_off: "Anturin kosteusmittausarvo normalisoitunut.",
  humidity_deviation:
    "Suhteellisen kosteuden poikkeama keskiarvosta hälytys aktivoitui {value}/{limit} prosenttiyksikköä.",
  humidity_deviation_off:
    "Suhteellisen kosteuden poikkeama keskiarvosta normalisoitunut.",
  gateway_api_access_changed: "API-pääsyn tilaa muutettiin.",
  not_defined: "Ei määritelty",
};

export default fi;
