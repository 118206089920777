import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import { ResultTypes } from "../../Utilities";

const DetailsBackLink = ({ backResultType, handleResultTypeChange }) => {
  const formatId =
    backResultType === ResultTypes.TYPE_GATEWAYS
      ? "back_to_ccu"
      : backResultType === ResultTypes.TYPE_SITES
      ? "back_to_site"
      : backResultType === ResultTypes.TYPE_USERS
      ? "back_to_user"
      : "back_to_mcu";

  return (
    <Button
      className="device-owned-close-btn"
      color="link"
      onClick={() => handleResultTypeChange()}
    >
      <p>
        <FormattedMessage id={formatId} />
      </p>
    </Button>
  );
};

export default DetailsBackLink;
