import React, { memo, useState, useEffect, useRef } from "react";
import { Modal, Button } from "reactstrap";
import { useIntl } from "react-intl";
import { getExportDownloadUrl } from "../Frontend-api";

const ExportDataModal = ({ siteId, toggle, handleErrors }) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);
  const [error, setError] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const linkTimeoutRef = useRef(null);
  const pollTimeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (pollTimeoutRef.current !== null) {
        clearTimeout(pollTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let token = null;
    const downloadData = async () => {
      setLoading(true);
      try {
        pollTimeoutRef.current = null;
        const result = await getExportDownloadUrl(siteId, token);
        token = result.token;

        if (result.status === "completed") {
          setDownloadUrl(result.downloadUrl);
          setLoading(false);
        } else if (result.status === "error") {
          setError(true);
        } else {
          pollTimeoutRef.current = setTimeout(() => {
            downloadData();
          }, 5000);
        }
      } catch (e) {
        toggle();
        handleErrors(e);
        setLoading(false);
      }
    };

    if (downloadUrl === null && !loading && !error) {
      downloadData();
    }
  }, [handleErrors, siteId, toggle, downloadUrl, loading, error]);

  useEffect(() => {
    if (downloadUrl !== null) {
      if (linkTimeoutRef.current !== null) {
        clearTimeout(linkTimeoutRef.current);
      }

      // Download link expiration 3600 seconds
      linkTimeoutRef.current = setTimeout(() => {
        setExpired(true);
      }, 3600 * 1000);
    }
  }, [downloadUrl]);

  return (
    <Modal
      isOpen={true}
      toggle={() => toggle()}
      className="confirmation-dialog text-center"
    >
      <h2>{formatMessage({ id: "site_data_export_title" })}</h2>
      <div className="d-flex flex-column">
        {expired || error ? (
          <div className="my-4">
            <span className="font-weight-bold">
              {formatMessage({
                id: expired
                  ? "site_data_export_expired"
                  : "site_data_export_error",
              })}
            </span>
          </div>
        ) : loading ? (
          <div className="icon-spin mt-0"></div>
        ) : downloadUrl !== null ? (
          <div className="my-4">
            <a
              className="btn btn-primary"
              href={downloadUrl}
              onClick={() => toggle()}
            >
              {formatMessage({ id: "site_data_export_download" })}
            </a>
          </div>
        ) : null}
      </div>
      <div className="d-flex justify-content-center">
        <Button color="link" onClick={() => toggle()}>
          {formatMessage({ id: "cancel" })}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(ExportDataModal);
