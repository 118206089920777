import React, { Fragment, memo, useState } from "react";
import { API } from "aws-amplify";
import {
  Form,
  FormGroup,
  CustomInput,
  ListGroupItem,
  ListGroupItemText,
} from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ConfirmationModal,
  Detail,
  DetailHeader,
  ErrorAlert,
} from "../../Utilities";
import { withErrors } from "../../CustomHooks";

const ApiKeyDetails = ({
  is_admin,
  isApiEnabled,
  hasApiKey,
  latestApiKeyUsageTime,
  ...props
}) => {
  const { formatMessage, formatDate } = useIntl();
  const [isLoading, setLoading] = useState(false);
  const [confirmation, showConfirmation] = useState(false);
  const { errorText, handleErrors, toggleError, visible } = props;

  const enableApiKey = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const result = await API.put(
        "Vili-API",
        `/users/${props.id}/enable-api`,
        { body: {} }
      );
      props.update(result);
    } catch (error) {
      handleErrors(e);
    } finally {
      showConfirmation(false);
      setLoading(false);
    }
  };

  const removeApiKey = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await API.put(
        "Vili-API",
        `/users/${props.id}/disable-api`,
        { body: {} }
      );
      props.update(result);
    } catch (e) {
      handleErrors(e);
    } finally {
      showConfirmation(false);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <ErrorAlert
        isOpen={visible}
        toggle={() => toggleError()}
        message={errorText}
      />
      <ListGroupItem>
        <DetailHeader
          title={<FormattedMessage id="api_key_header" />}
          info={<FormattedMessage id="api_key_info" />}
        />
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage id="api_key_status" />
            </ListGroupItemText>
          }
          muted={
            <small>
              {`${formatMessage({
                id: "api_key_last_used",
              })} ${
                !latestApiKeyUsageTime
                  ? formatMessage({ id: "api_key_last_used_never" })
                  : formatDate(latestApiKeyUsageTime, {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
              }`}
            </small>
          }
          caret={
            <Form>
              <FormGroup className="mb-0">
                <CustomInput
                  id="api-key-status-switch"
                  type="switch"
                  disabled={is_admin}
                  label={formatMessage({
                    id: isApiEnabled ? "api_key_enabled" : "api_key_disabled",
                  })}
                  checked={isApiEnabled}
                  onChange={() => showConfirmation(true)}
                />
              </FormGroup>
            </Form>
          }
        />
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage id="api_key_generated" />
            </ListGroupItemText>
          }
          middle={
            <div>
              <small>
                {formatMessage({
                  id: hasApiKey ? "yes" : "no",
                })}
              </small>
            </div>
          }
        />
      </ListGroupItem>
      <ConfirmationModal
        isOpen={confirmation}
        toggle={() => showConfirmation(!confirmation)}
        handleSubmit={(e) => (isApiEnabled ? removeApiKey(e) : enableApiKey(e))}
        isLoading={isLoading}
        text={isApiEnabled ? "api_key_disable" : "api_key_enable"}
        loadingText={isApiEnabled ? "api_key_disabling" : "api_key_enabling"}
      >
        <FormattedMessage
          id={
            isApiEnabled ? "api_key_disable_confirm" : "api_key_enable_confirm"
          }
        />
      </ConfirmationModal>
    </Fragment>
  );
};

export default memo(withErrors(ApiKeyDetails));
