import React from "react";
import { Button } from "reactstrap";
import "./Buttons.css";
import { FormattedMessage } from "react-intl";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) => (
  <Button
    block
    type="submit"
    color="primary"
    className={`LoaderButton ${className} mt-3`}
    disabled={disabled || isLoading}
    {...props}
  >
    <h6>
      {isLoading && <div className="spinning-btn" />}
      {!isLoading ? (
        <FormattedMessage id={text} />
      ) : (
        <FormattedMessage id={loadingText} />
      )}
    </h6>
  </Button>
);
