import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import Amplify, { Auth } from "aws-amplify";
import config from "./config";
import Localize from "./Localize";
import * as serviceWorker from "./serviceWorker";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
  },
  API: {
    endpoints: [
      {
        name: "Vili-API",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          try {
            const authorization = await (
              await Auth.currentSession()
            ).idToken.jwtToken;
            return {
              Authorization: authorization,
            };
          } catch (e) {
            if (e === "No current user") {
              window.location.reload(true);
            }
          }
        },
      },
    ],
  },
});

ReactDOM.render(
  <Router>
    <Localize>
      <App />
    </Localize>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
