import React from "react";
import { Button, ListGroupItem, ListGroupItemText } from "reactstrap";
import { FormattedMessage, FormattedTime, FormattedDate } from "react-intl";
import { Detail, DetailHeader, navigateToNormalUI } from "../../Utilities";
import { FaExternalLinkAlt } from "react-icons/fa";
import { ResultTypes } from "../../Utilities";
import Usage from "./Usage";
import DeviceTypes from "../../Utilities/DeviceTypes";

export default ({
  children,
  id,
  type,
  serialNumber,
  name,
  publicLink,
  owner,
  isOnline,
  isAlert,
  group,
  purpose,
  constructionMaterial,
  latestMeasurements,
  latestCommunicationTime,
  handleResultTypeChange,
}) => {
  return (
    <ListGroupItem>
      <DetailHeader
        title={<FormattedMessage id="header_deviceDetails" />}
        info={<FormattedMessage id="header_deviceDetails" />}
      />
      {(type === DeviceTypes.TYPE_MCU1 || type === DeviceTypes.TYPE_MCU2) && (
        <Detail
          detail={
            <Usage
              data={latestMeasurements}
              status={{ isOnline, isAlert }}
              purpose={purpose}
            />
          }
          report={children}
        />
      )}

      <Detail
        detail={
          <ListGroupItemText>
            <FormattedMessage tagName="strong" id="header_name" />
          </ListGroupItemText>
        }
        middle={
          <div className="d-flex flex-row align-items-center">
            <Button
              className="d-flex flex-row align-items-center devices-owned-btn p-0"
              color="link"
              onClick={(e) => {
                e.preventDefault();
                navigateToNormalUI(id, "devices");
              }}
            >
              <ListGroupItemText>
                {!name ? <FormattedMessage id="no_given_identifier" /> : name}
              </ListGroupItemText>
              <FaExternalLinkAlt
                className="condition-icons margin-left-10px"
                size={20}
              />
            </Button>
            <div className="margin-left-10px">
              <FormattedMessage tagName={"small"} id="view_site_info" />
            </div>
          </div>
        }
      />
      <Detail
        detail={
          <ListGroupItemText>
            <FormattedMessage tagName="strong" id="header_id" />
          </ListGroupItemText>
        }
        middle={<ListGroupItemText>{id}</ListGroupItemText>}
      />
      <Detail
        detail={
          <ListGroupItemText>
            <FormattedMessage tagName="strong" id="header_serial" />
          </ListGroupItemText>
        }
        middle={<ListGroupItemText>{serialNumber}</ListGroupItemText>}
      />
      <Detail
        detail={
          <ListGroupItemText>
            <FormattedMessage tagName="strong" id="header_type" />
          </ListGroupItemText>
        }
        middle={<ListGroupItemText>{type}</ListGroupItemText>}
      />
      {(type === DeviceTypes.TYPE_MCU1 || type === DeviceTypes.TYPE_MCU2) && (
        <>
          <Detail
            detail={
              <ListGroupItemText>
                <FormattedMessage
                  tagName="strong"
                  id="header_constructionMaterial"
                />
              </ListGroupItemText>
            }
            middle={
              <ListGroupItemText>
                {!constructionMaterial ? (
                  ""
                ) : (
                  <FormattedMessage id={constructionMaterial} />
                )}
              </ListGroupItemText>
            }
          />
          <Detail
            detail={
              <ListGroupItemText>
                <FormattedMessage tagName="strong" id="header_purpose" />
              </ListGroupItemText>
            }
            middle={
              <ListGroupItemText>
                {!purpose ? "" : <FormattedMessage id={`purpose_${purpose}`} />}
              </ListGroupItemText>
            }
          />
          <Detail
            detail={
              <ListGroupItemText>
                <FormattedMessage tagName="strong" id="header_latest_rpm" />
              </ListGroupItemText>
            }
            middle={
              !latestMeasurements || !latestMeasurements[0]
                ? ""
                : latestMeasurements[0].value
            }
            measurements={
              !latestMeasurements || !latestMeasurements[0] ? (
                ""
              ) : !latestMeasurements[0].timestamp ? (
                ""
              ) : (
                <ListGroupItemText className="text-muted" tag="em">
                  <FormattedTime
                    value={latestMeasurements[0].timestamp}
                    year="numeric"
                    day="numeric"
                    month="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                  />
                </ListGroupItemText>
              )
            }
          />
        </>
      )}

      <Detail
        detail={
          <ListGroupItemText>
            <FormattedMessage tagName="strong" id="header_owner" />
          </ListGroupItemText>
        }
        middle={
          !owner ? (
            ""
          ) : (
            <Button
              className="devices-owned-btn"
              color="link"
              onClick={() =>
                handleResultTypeChange(owner.id, ResultTypes.TYPE_USERS)
              }
            >
              <p className="btn-p">{owner.email}</p>
            </Button>
          )
        }
      />
      {(type === DeviceTypes.TYPE_MCU1 || type === DeviceTypes.TYPE_MCU2) && (
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage tagName="strong" id="header_group" />
            </ListGroupItemText>
          }
          middle={
            <ListGroupItemText>{!group ? "" : group.name}</ListGroupItemText>
          }
        />
      )}

      <Detail
        detail={
          <ListGroupItemText>
            <FormattedMessage tagName="strong" id="header_publicLink" />
          </ListGroupItemText>
        }
        middle={
          <ListGroupItemText
            className={!publicLink?.identifier ? "text-muted" : ""}
          >
            {!publicLink?.identifier ? (
              <FormattedMessage tagName="small" id="not_defined" />
            ) : (
              `${publicLink.url}/${publicLink.identifier}`
            )}
          </ListGroupItemText>
        }
      />
      {latestCommunicationTime && (
        <Detail
          detail={
            <ListGroupItemText>
              <FormattedMessage
                tagName="strong"
                id="header_gateway_latestCommunicationTime"
              />
            </ListGroupItemText>
          }
          middle={
            <ListGroupItemText>
              <FormattedDate
                value={latestCommunicationTime}
                year="numeric"
                month="2-digit"
                day="2-digit"
                hour="2-digit"
                minute="2-digit"
                second="2-digit"
              />
            </ListGroupItemText>
          }
        />
      )}
    </ListGroupItem>
  );
};
