import React, { useState } from "react";
import { Card, Form, FormGroup, Input, InputGroup } from "reactstrap";
import { useIntl } from "react-intl";
import "./forms.css";
import { LoaderButton, ShowHideButton } from "../Buttons";
import { ErrorAlert } from "../Utilities";
import useForm, { useErrorHandling } from "../CustomHooks";
import { Auth, API } from "aws-amplify";

const Login = (props) => {
  const { formatMessage } = useIntl();
  const [type, setType] = useState("password");
  const [isLoading, setLoading] = useState(false);
  const { errorText, visible, toggleError, handleErrors } = useErrorHandling();

  const validateInputs = () => {
    return inputs.email && inputs.password ? true : false;
  };

  const signIn = async () => {
    setLoading(true);
    try {
      const user = await Auth.signIn(inputs.email, inputs.password);
      let { email } = user.attributes;
      const isAdmin = await API.get("Vili-API", "/users/is-admin", email);
      if (!isAdmin.result) {
        setLoading(false);
        let e = { name: "NotAuthorizedException" };
        handleErrors(e);
        await Auth.signOut();
      } else {
        props.handleLogin(email);
      }
    } catch (e) {
      setLoading(false);
      handleErrors(e);
    } finally {
    }
  };

  const { inputs, handleInputChange, handleSubmit } = useForm(signIn);

  return (
    <Card className="form-card">
      <ErrorAlert
        isOpen={visible}
        toggle={() => toggleError()}
        message={errorText}
      />
      <div className="img-logo" />
      <h3>{formatMessage({ id: "login_title" })}</h3>
      <Form className="login-form" onSubmit={(e) => handleSubmit(e)}>
        <FormGroup>
          <Input
            name="email"
            type="text"
            maxLength={50}
            placeholder={formatMessage({ id: "email_placeholder" })}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup className="text-left">
          <InputGroup>
            <ShowHideButton
              type={type}
              showHide={() =>
                setType((prev) => (prev === "text" ? "password" : "text"))
              }
            />
          </InputGroup>
          <Input
            name="password"
            type={type}
            maxLength={50}
            placeholder={formatMessage({ id: "password_placeholder" })}
            onChange={handleInputChange}
          />
        </FormGroup>
        <LoaderButton
          text="login"
          loadingText="login_loading"
          isLoading={isLoading}
          disabled={!validateInputs()}
        />
      </Form>
    </Card>
  );
};

export default Login;
