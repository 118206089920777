import React, { Fragment, memo } from "react";
import { ListGroup, Modal, ModalHeader, ModalBody } from "reactstrap";
import UserDetails from "../Details/UserDetails";
import DeviceDetails from "../Details/DeviceDetails";
import { BackButton } from "../../Buttons";
import SiteDetails from "../Details/SiteDetails";
import { useSelectedEntity, ResultTypes } from "../../Utilities";
import { useHistory } from "react-router-dom";

const ResultDetails = ({ type, id, selectEntity, ...props }) => {
  return type === "users" ? (
    <UserDetails
      userId={id}
      resultId={id}
      resultType={type}
      selectEntity={selectEntity}
      {...props}
    />
  ) : type === "sites" ? (
    <SiteDetails
      siteId={id}
      resultId={id}
      resultType={type}
      selectEntity={selectEntity}
      {...props}
    />
  ) : (
    <DeviceDetails
      deviceId={id}
      resultId={id}
      resultType={type}
      selectEntity={selectEntity}
      {...props}
    />
  );
};

const getSelectedId = (e) => {
  return e.siteId || e.userId || e.deviceId || e.gatewayId;
};

const ResultModal = ({
  detailsId,
  handleStatusChange,
  resultType,
  deviceType,
}) => {
  const history = useHistory();

  const [selectedEntity, selectEntity] = useSelectedEntity(
    resultType,
    detailsId,
    deviceType
  );

  const closeModal = () => {
    if (resultType === ResultTypes.TYPE_GATEWAYS) {
      history.push(`/${ResultTypes.TYPE_DEVICES}`);
    } else {
      history.push(`/${resultType}`);
    }
  };

  return (
    <Fragment>
      <Modal
        id={detailsId}
        backdrop={false}
        className="result-modal text-center"
        isOpen={detailsId !== undefined}
      >
        <ModalHeader>
          <BackButton onClick={() => closeModal()} />
        </ModalHeader>
        <ModalBody>
          <ListGroup className="details-list-group" flush>
            {selectedEntity !== undefined ? (
              <ResultDetails
                id={getSelectedId(selectedEntity)}
                type={selectedEntity.type}
                selectEntity={selectEntity}
                deviceType={deviceType}
                handleStatusChange={handleStatusChange}
                backResultType={resultType}
              />
            ) : (
              <ResultDetails
                id={detailsId}
                type={resultType}
                deviceType={deviceType}
                selectEntity={selectEntity}
                handleStatusChange={handleStatusChange}
              />
            )}
          </ListGroup>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default memo(ResultModal);
