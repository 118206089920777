import React from "react";
import { useIntl } from "react-intl";

const status = (type) => {
  return type === "user_note"
    ? "fa-user"
    : type === "device_alarm_on"
    ? "fa-exclamation-triangle"
    : "no-icon";
};

const getAlarmSource = ({ serialNumber, product, transmitter }) => {
  return !transmitter
    ? !product
      ? serialNumber
      : !product.name
      ? product.serial
      : product.name
    : !transmitter.name
    ? transmitter.serial
    : transmitter.name;
};

export default ({
  serialNumber,
  type,
  attributes,
  timestamp,
  user,
  ...rest
}) => {
  const { formatMessage, formatDate } = useIntl();
  const device_name = getAlarmSource({ serialNumber, ...rest });
  const messageAttributes = {};

  const message = (msgType, msgAttributes) => {
    if (msgType === "device_alarm_on") {
      msgAttributes.map((attr) =>
        Object.assign(messageAttributes, { [attr.type]: attr.value })
      );

      return `${device_name}: ${formatMessage(
        { id: [messageAttributes.type] },
        { ...messageAttributes }
      )}`;
    } else if (msgType === "device_alarm_off") {
      msgAttributes.map((attr) =>
        Object.assign(messageAttributes, { [attr.type]: `${attr.value}_off` })
      );
      return `${device_name}: ${formatMessage(
        { id: [messageAttributes.type] },
        { ...messageAttributes }
      )}`;
    } else if (msgType === "user_status_changed") {
      msgAttributes.map((attr) =>
        Object.assign(messageAttributes, { [attr.type]: attr.value })
      );
      const { old_status, new_status, user_id } = messageAttributes;
      let formatted_old_status = formatMessage({ id: `from_${old_status}` });
      let formatted_new_status = formatMessage({ id: `to_${new_status}` });
      return formatMessage(
        { id: msgType },
        {
          user_id: [user_id],
          old_status: formatted_old_status,
          new_status: formatted_new_status,
        }
      );
    } else if (msgType === "user_note") {
      msgAttributes.map((attr) =>
        Object.assign(messageAttributes, { [attr.type]: attr.value })
      );
      return formatMessage({ id: msgType }, { ...messageAttributes });
    } else if (
      msgType === "device_public_link_identifier_updated" ||
      msgType === "group_public_link_identifier_updated"
    ) {
      msgAttributes.map((attr) =>
        Object.assign(messageAttributes, { [attr.type]: attr.value })
      );
      return !messageAttributes.new_public_link_identifier
        ? `${device_name}: ${formatMessage({
            id: "public_link_identifier_deleted",
          })}`
        : `${device_name}: ${formatMessage(
            { id: msgType },
            { ...messageAttributes }
          )}`;
    } else if (msgType === "transmitter_alarm_limits_changed") {
      let limits = msgAttributes.map((attr) =>
        attr.value === "null"
          ? formatMessage({ id: `${[attr.type]}_off` })
          : formatMessage({ id: [attr.type] }, { limit: attr.value })
      );

      return `${device_name}: ${formatMessage(
        { id: msgType },
        {
          alarm_limits: limits[0],
        }
      )}`;
    } else {
      msgAttributes.map((attr) =>
        Object.assign(messageAttributes, { [attr.type]: attr.value })
      );
      return !device_name
        ? formatMessage({ id: msgType }, { ...messageAttributes })
        : `${device_name}: ${formatMessage(
            { id: msgType },
            { ...messageAttributes }
          )}`;
    }
  };

  const date = formatDate(timestamp, {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <li className={status(type) === "no-icon" ? "no-icon" : ""}>
      {status(type) !== "no-icon" && (
        <span className="fa-li">
          <i className={`fas ${status(type)}`}></i>
        </span>
      )}
      <span>{message(type, attributes)}</span>
      <small className="d-block text-muted">
        {!user
          ? date
          : `${date}, ${formatMessage(
              { id: "created" },
              { email: user.email }
            )}`}
      </small>
    </li>
  );
};
