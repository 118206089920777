import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import "./Utilities.css";

export default ({ isAdmin, children }) => {
  return (
    <UncontrolledDropdown tag="div" direction="left">
      <DropdownToggle
        disabled={isAdmin}
        tag="div"
        className="p-2 ml-auto list-item-menu"
      />
      <DropdownMenu>{children}</DropdownMenu>
    </UncontrolledDropdown>
  );
};
