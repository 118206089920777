const DeviceTypes = {
  TYPE_CCU1: "VILPE CCU-1",
  TYPE_MCU1: "VILPE MCU-1",
  TYPE_MCU2: "VILPE MCU-2",
  TYPE_SENSOR1: "VILPE RHT-1",
  TYPE_SENSOR2: "VILPE RHT-2",
};

const SimpleDeviceType = {
  [DeviceTypes.TYPE_CCU1]: "ccu-1",
  [DeviceTypes.TYPE_MCU1]: "mcu-1",
  [DeviceTypes.TYPE_MCU2]: "mcu-2",
  [DeviceTypes.TYPE_SENSOR1]: "rht-1",
  [DeviceTypes.TYPE_SENSOR2]: "rht-2",
};

const toSimpleDeviceType = (type) => {
  return SimpleDeviceType[type];
};

const fromSimpleDeviceType = (simpleType) => {
  const entry = Object.entries(SimpleDeviceType).find(
    ([_key, value]) => value === simpleType
  );
  return entry ? entry[0] : undefined;
};

export default DeviceTypes;
export { SimpleDeviceType, toSimpleDeviceType, fromSimpleDeviceType };
