import React from "react";
import { IntlProvider } from "react-intl";
import fi from "./fi";

const locales = { fi };

//const KEY = "locale";

// -- Apufunktio aikaleiman käsittelyyn: vaihtaa vuoden ja päivän paikat keskenään
const swap = (arr, a, b) => {
  return ([arr[a], arr[b]] = [arr[b], arr[a]]) && arr;
};

// -- Aikaleiman formatointi ja uudelleenrakennus toivottuun muotoon
export const formatDate = (timestamp) => {
  const formatter = new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const dateString = swap(formatter.formatToParts(timestamp), 0, 4)
    .map(({ value }) => {
      return value.includes(",") ? value.replace(/,/g, "") : value;
    })
    .reduce((string, part) => string + part);

  return dateString;
};

export default props => {
  const locale = "fi";

  return (
    <IntlProvider locale={locale} messages={locales[locale]}>
      {props.children}
    </IntlProvider>
  );
};

/*
export default class Localize extends Component {
  constructor(props) {
    super(props);

    let locale = localStorage.getItem(KEY);
    if (!locale) {
      locale = global.navigator.language;
    }
    if (!(locale in locales)) {
      locale = "en";
    }
    this.state = { locale };
  }
  handleLocaleChange = locale => {
    localStorage.setItem(KEY, locale);
  };
  render() {
    return (
      <IntlProvider
        locale={this.state.locale}
        messages={locales[this.state.locale]}
      >
        {this.props.children}
      </IntlProvider>
    );
  }
}*/
