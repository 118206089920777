import React, { memo, useState, Fragment } from "react";
import {
  ListGroupItem,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Loader, Detail, DetailHeader } from "../../../Utilities";
import EventNote from "./EventNote";
import "./eventlog.css";

const Eventlog = ({ serialNumber, log, isLoading }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;

  const handlePageChange = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const pageCount = Math.ceil(log.length / pageSize);

  return !isLoading ? (
    <Fragment>
      <ListGroupItem>
        <DetailHeader
          title={<FormattedMessage id="header_log" />}
          info={<FormattedMessage id="header_log" />}
        />
        <Detail
          log={
            <div className="timeline-container">
              <ul className="timeline fa-ul">
                {log
                  .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                  .map(({ ...items }, index) => (
                    <EventNote
                      key={index}
                      serialNumber={serialNumber}
                      {...items}
                    />
                  ))}
              </ul>
            </div>
          }
        />
        <Detail
          pagination={
            pageCount > 1 && (
              <Pagination className="log-pagination">
                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink
                    className="log-pagination-link"
                    onClick={(e) => handlePageChange(e, currentPage - 1)}
                    previous
                    href="#"
                  />
                </PaginationItem>

                {[...Array(pageCount)].map((page, i) => (
                  <PaginationItem active={i === currentPage} key={i}>
                    <PaginationLink
                      className="log-pagination-link"
                      onClick={(e) => handlePageChange(e, i)}
                      href="#"
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem disabled={currentPage >= pageCount - 1}>
                  <PaginationLink
                    className="log-pagination-link"
                    onClick={(e) => handlePageChange(e, currentPage + 1)}
                    next
                    href="#"
                  />
                </PaginationItem>
              </Pagination>
            )
          }
        />
      </ListGroupItem>
    </Fragment>
  ) : (
    <Loader isLoading={isLoading} />
  );
};

export default memo(Eventlog);
