import React, { Fragment, useState, useEffect } from "react";
import { ListGroup, Fade } from "reactstrap";
import { ErrorAlert, Loader } from "../../Utilities";
import Transmitters from "./Transmitters";
import ListMcuDetails from "./ListMcuDetails";
import ListCcuDetails from "./ListCcuDetails";
import Eventlog from "./Eventlog";
import { DetailReportButton } from "../../Buttons";
import { withErrors, usePrevious } from "../../CustomHooks";
import DeviceTypes from "../../Utilities/DeviceTypes";
import { reverse } from "lodash";
import { getDetails, getLog } from "../../Frontend-api";
import TransmitterConfigurationVersions from "./TransmitterConfigurationVersions";
import DetailsBackLink from "./DetailsBackLink";

const DeviceDetails = ({
  deviceId,
  resultType,
  selectEntity,
  deviceType,
  ...props
}) => {
  const [isLoading, setLoading] = useState(true);
  const [log, setLog] = useState([]);
  const [details, setDetails] = useState({});
  const { errorText, handleErrors, toggleError, visible } = props;
  const previousId = usePrevious(deviceId);

  useEffect(() => {
    async function getItemDetails(type, id) {
      setLoading(true);
      try {
        const details = await getDetails(type, id);
        setDetails(details);
      } catch (e) {
        handleErrors(e);
      } finally {
        setLoading(false);
      }
    }

    async function getLogs(type, id) {
      try {
        const events = await getLog(type, id);
        const log = reverse(events.log);
        setLog(log);
      } catch (e) {
        handleErrors(e);
      }
    }

    if (!deviceId) {
      return;
    } else if (previousId !== deviceId) {
      getItemDetails(resultType, deviceId);
      getLogs(resultType, deviceId);
    }
  }, [resultType, deviceId, previousId, handleErrors]);

  const handleResultTypeChange = (id, type) => {
    selectEntity(id, type, deviceType);
  };

  return (
    <Fragment>
      <ErrorAlert
        isOpen={visible}
        toggle={() => toggleError()}
        message={errorText}
      />
      {props.backResultType && (
        <DetailsBackLink
          backResultType={props.backResultType}
          handleResultTypeChange={handleResultTypeChange}
        />
      )}
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        details && (
          <Fade in={true}>
            <ListGroup>
              {details.type === DeviceTypes.TYPE_CCU1 ? (
                <ListCcuDetails
                  sites={details.roofs}
                  {...details}
                  resultType={resultType}
                  handleResultTypeChange={handleResultTypeChange}
                />
              ) : (
                <Fragment>
                  <ListMcuDetails
                    {...details}
                    resultType={resultType}
                    handleResultTypeChange={handleResultTypeChange}
                  >
                    <DetailReportButton details={details} {...props} />
                  </ListMcuDetails>
                  {(details.type === DeviceTypes.TYPE_MCU1 ||
                    details.type === DeviceTypes.TYPE_MCU2) && (
                    <>
                      <Transmitters {...details} />
                      <TransmitterConfigurationVersions
                        configurations={
                          details.transmitterConfigurationVersions
                        }
                      />
                    </>
                  )}
                </Fragment>
              )}
              <Eventlog
                log={log}
                serialNumber={details.serialNumber}
                isLoading={isLoading}
              />
            </ListGroup>
          </Fade>
        )
      )}
    </Fragment>
  );
};

export default withErrors(DeviceDetails);
