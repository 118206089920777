import React, { Fragment, memo, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { DropdownItem, ListGroupItem, ListGroupItemText } from "reactstrap";
import { FormattedMessage } from "react-intl";
import {
  ConfirmationModal,
  Detail,
  DetailHeader,
  ErrorAlert,
} from "../../Utilities";
import { withErrors } from "../../CustomHooks";

const UserName = ({
  email,
  is_admin,
  id,
  handleStatusChange,
  status: currentStatus,
  ...props
}) => {
  const [status, setStatus] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [confirmation, showConfirmation] = useState(false);
  const { errorText, handleErrors, toggleError, visible } = props;

  useEffect(() => {
    let params = { body: {} };
    const userId = id;
    async function disableUser() {
      try {
        await API.put("Vili-API", `/users/${userId}/disable`, params);
        handleStatusChange && handleStatusChange(status);
      } catch (e) {
        setStatus("");
        handleErrors(e);
      }
      setStatus("");
    }

    async function enableUser() {
      try {
        await API.put("Vili-API", `/users/${userId}/enable`, params);
        handleStatusChange && handleStatusChange(status);
      } catch (e) {
        setStatus("");
        handleErrors(e);
      }
      setStatus("");
    }

    async function deleteUser() {
      setLoading(true);
      try {
        await API.del("Vili-API", `/users/${userId}`, params);
        handleStatusChange && handleStatusChange(status);
      } catch (e) {
        setStatus("");
        setLoading(false);
        showConfirmation(false);
        handleErrors(e);
      }
      setStatus("");
    }

    if (status === "enabled") {
      enableUser();
    }

    if (status === "disabled") {
      disableUser();
    }

    if (status === "deleted") {
      deleteUser();
    }
  }, [status, id, handleStatusChange, handleErrors]);

  return (
    <Fragment>
      <ErrorAlert
        isOpen={visible}
        toggle={() => toggleError()}
        message={errorText}
      />
      <ListGroupItem>
        <DetailHeader
          title={<FormattedMessage id="username" />}
          info={<FormattedMessage id="details_info_username" />}
        />
        <Detail
          isAdmin={is_admin}
          detail={
            !email ? (
              <ListGroupItemText className="text-muted">
                <FormattedMessage id="no_username" />
              </ListGroupItemText>
            ) : (
              <ListGroupItemText>{email}</ListGroupItemText>
            )
          }
          middle={
            <small>
              <FormattedMessage id={`status_${currentStatus}`} />
            </small>
          }
          menuItems={
            <Fragment>
              <DropdownItem
                disabled={currentStatus === "enabled"}
                onClick={() => setStatus("enabled")}
              >
                <FormattedMessage id="user_activate" />
              </DropdownItem>
              <DropdownItem
                disabled={currentStatus === "disabled"}
                onClick={() => setStatus("disabled")}
              >
                <FormattedMessage id="user_deactivate" />
              </DropdownItem>
              <DropdownItem onClick={() => showConfirmation(true)}>
                <FormattedMessage id="user_delete" />
              </DropdownItem>
            </Fragment>
          }
        />
        <ConfirmationModal
          isOpen={confirmation}
          toggle={() => showConfirmation(!confirmation)}
          handleSubmit={(e) => {
            e.preventDefault();
            setStatus("deleted");
          }}
          isLoading={isLoading}
          text="user_delete"
          loadingText="user_deleting"
        >
          <FormattedMessage
            id="user_delete_confirm"
            values={{ user_id: email }}
          />
        </ConfirmationModal>
      </ListGroupItem>
    </Fragment>
  );
};

export default memo(withErrors(UserName));
