import React from "react";
import { withRouter } from "react-router-dom";


const Backbutton = ({ onClick, ...props}) => (
    <span className="back-arrow mr-3"
      onClick={() => onClick ? onClick() : props.history.goBack()}
    />
);

export default withRouter(Backbutton);