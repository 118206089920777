import React, { memo, useState } from "react";
import { Card, Collapse, Table } from "reactstrap";
import { useIntl } from "react-intl";
import { CustomCaret } from "../../../Utilities";

const TransmitterConfiguration = ({
  version,
  status,
  timeAcknowledged,
  ...params
}) => {
  const { formatMessage, formatDate } = useIntl();
  const [collapse, setCollapseOpen] = useState(false);
  return (
    <div
      //   className="d-flex flex-column "
      style={{ cursor: "pointer" }}
      onClick={() => setCollapseOpen(!collapse)}
    >
      <Table responsive className="transmitter-conf-table m-0">
        <tbody>
          <tr className="transmitter-conf-row">
            <td>
              <strong>{formatMessage({ id: "header_version" })}</strong> <br />
              <span className="ml-3">{version}</span>
            </td>
            <td>
              <strong>
                {formatMessage({ id: "header_configuration_status" })}
              </strong>
              <br />
              <span>{formatMessage({ id: `status_${status}` })}</span>
            </td>
            <td>
              <strong>
                {formatMessage({ id: "header_timeAcknowledged" })}
              </strong>
              <br />
              <span>
                {timeAcknowledged
                  ? formatDate(timeAcknowledged, {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })
                  : "-"}
              </span>
            </td>
            <td className="text-right">
              <CustomCaret isOpen={collapse} />
            </td>
          </tr>
        </tbody>
      </Table>

      <Collapse isOpen={collapse}>
        <Card body className="transmitter-setting-card">
          {Object.entries(params).map(([key, value]) =>
            key.toLowerCase().includes("time") ? (
              <div key={key} className="d-flex flex-row">
                <div className="p-2 text-left ">
                  <strong>{formatMessage({ id: `header_${key}` })}</strong>
                </div>
                <div className="p-2">
                  {value !== null
                    ? formatDate(value, {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })
                    : "-"}
                </div>
              </div>
            ) : (
              <div key={key} className="d-flex flex-row">
                <div className="p-2 text-left ">
                  <strong>{formatMessage({ id: `header_${key}` })}</strong>
                </div>
                <div className="p-2">{!value ? "-" : value}</div>
              </div>
            )
          )}
        </Card>
      </Collapse>
    </div>
  );
};

export default memo(TransmitterConfiguration);
