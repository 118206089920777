import React from "react";
import { Button, ListGroupItem, ListGroupItemText } from "reactstrap";
import { useIntl } from "react-intl";
import { Detail, DetailHeader, ResultTypes } from "../../Utilities";
import "./details.css";

const ListOwnedGateways = ({ gateways_owned, handleResultTypeChange }) => {
  const { formatMessage } = useIntl();
  return (
    <ListGroupItem>
      <DetailHeader
        title={formatMessage({ id: "gateways_owned" })}
        info={formatMessage({ id: "details_info_gateways" })}
      />
      {!gateways_owned || !gateways_owned.length ? (
        <ListGroupItemText className="text-muted">
          {formatMessage({ id: "no_gateways_owned" })}
        </ListGroupItemText>
      ) : (
        gateways_owned.map((item) => (
          <Detail
            key={item.id}
            detail={
              <Button
                disabled={item.isPlanned}
                className="devices-owned-btn"
                color="link"
                onClick={() =>
                  handleResultTypeChange(item.id, ResultTypes.TYPE_GATEWAYS)
                }
              >
                <p>
                  {!item.name
                    ? item.serial_number
                      ? item.serial_number
                      : item.serialNumber
                    : item.name}
                </p>
              </Button>
            }
            middle={
              <div className="d-flex flex-row">
                {!item.name && <span>{item.serial_number}</span>}
                {item.isPlannedOrInstalledOnThisRoof === false && (
                  <span className="margin-left-10px">
                    {formatMessage({ id: "site_report_not_included_in_site" })}
                  </span>
                )}
              </div>
            }
          />
        ))
      )}
    </ListGroupItem>
  );
};

export default ListOwnedGateways;
